export const logo = 'https://gfc.target.com/elevatewebsiteproduction/elevatewebsite2022_TargetElevate_newlogo.png';
export const TickMark = 'https://gfc.target.com/elevatewebsiteproduction/Tick-Mark-Once.gif';
export const headerBannerBg = 'https://gfc.target.com/stencil/nonprod/1702618328326_banner_Registration-1-.png';
export const headerBannerMobile = 'https://gfc.target.com/stencil/nonprod/1702618327685_banner_Registration_mobile.png';
// export const unsubscribed = 'https://gfc.target.com/stencil/nonprod/1704368260826_tick_icon.png';
export const closebutton = 'https://gfc.target.com/elevatewebsiteproduction/closebutton.png';

// 2024 addittions
export const headerLogo = 'https://gfc.target.com/stencil/stencil/1720688317564_meetup-logo.png';
export const whiteCrossSvg = 'https://gfc.target.com/elevatewebsiteproduction/white_cross.svg';
export const crossSvg = 'https://gfc.target.com/elevatewebsiteproduction/cross.svg';
export const unsubscribed = 'https://gfc.target.com/stencil/nonprod/1715783174616_nonprod_1704368260826_tick_icon.png';
export const popUpBgImage = 'https://gfc.target.com/multiplai/popupbg.png';
export const r_form_bg = 'https://gfc.target.com/multiplai/2024/r_form_bg.png';
export const popUpBg = 'https://gfc.target.com/stencil/stencil/1720690647145_Registration-box-2-.png';
export const mobilePopUpBg = 'https://gfc.target.com/stencil/stencil/1720692031342_Registration-box-6-.png';
export const consentForm = 'https://gfc.target.com/multiplai/2024/Consent_Form.pdf';
export const psDesktopBanner = 'https://gfc.target.com/multiplai/2024/ps_desktop.png';
export const psMobileBanner = 'https://gfc.target.com/multiplai/2024/ps_mobile.png';
export const galleryDesktopBanner = 'https://gfc.target.com/multiplai/2024/gallery_desktop.png';
