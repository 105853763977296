import { Button, Grid, Modal, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import MuiDataTable from 'mui-datatables';
import { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { GET_IBPSUSERS } from '../Utils/Queries/Queries';
import { useLazyQuery, useMutation } from '@apollo/client';
import { ADMIT_IBPS_USER } from '../Utils/Mutations/Mutations';
import * as React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  dataGridContainer: {
    padding: '0 30px',
    backgroundColor: '#FFF',
    '& table, thead th button': {
      //   fontFamily: 'HelveticaforTargetRegular',
    },
    '& table tbody td': {
      padding: '16px 16px 16px 32px',
      textAlign: 'left',
    },
    '& table thead tr th span button': {
      //   fontFamily: 'HelveticaforTargetBold !important',
      fontSize: '16px !important',
    },
  },
  regStatus: {
    // fontFamily: 'HelveticaforTargetRegular !important',
    padding: '3px',
    textAlign: 'center',
  },
  closeButton: {
    border: 'none',
    width: '20px',
    height: '20px',
    cursor: 'pointer',
    background: `url(https://gfc.target.com/elevatewebsiteproduction/cross.svg) no-repeat`,
    '&:focus-visible': {
      outline: '2px dotted #666666',
      outlineOffset: 2,
    },
    '&:hover': {
      opacity: 0.75,
      // backgroundColor: '#ffffff',
    },
  },
  dashKeyName: {
    // minWidth: '145px',
    fontSize: '13px !important',
    // fontFamily: 'HelveticaforTargetBold !important',
  },
  dashKeyValue: {
    // minWidth: '145px',
    fontSize: '13px !important',
    // fontFamily: 'HelveticaforTargetBold !important',
    border: '1px solid #CC0000',
    width: '50px',
    textAlign: 'center',
    backgroundColor: '#ff000017',
    color: '#CC0000',
  },
  dashSecContainer: {
    padding: '10px',
  },
  dashKeyContainer: {
    padding: '5px',
  },
  workshop1RadioButton: {
    color: '#000 !important',
    '&.Mui-checked': {
      color: '#000 !important',
    },
  },
  workshop2RadioButton: {
    color: '#000 !important',
    '&.Mui-checked': {
      color: '#000 !important',
    },
  },
}));
const Dashboard = () => {
  const classes = useStyles();

  const navigate = useNavigate();

  const columns = [
    {
      name: 'company', // we should use the same field name as in the json .
      label: 'Company Name',
    },
    {
      name: 'first_name',
      label: 'Participant Name',
    },
    {
      name: 'email',
      label: 'Email',
    },
    {
      name: 'contact_no',
      label: 'Mobile',
    },
    {
      name: 'is_admitted',
      label: 'Admission Status',
    },
    {
      name: 'action',
      label: 'Action',
    },
  ];
  const [getIbpsUsers, { data: usersData, refetch: usersRefetch }] = useLazyQuery(GET_IBPSUSERS, {
    context: {},
  });

  const [admitIbpsUser] = useMutation(ADMIT_IBPS_USER, {
    context: {},
  });

  useEffect(() => {
    try {
      getIbpsUsers();
    } catch (error) {
      console.log(error.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersData]);

  const approvedUsers = usersData && usersData.ibps;

  const [open, setOpen] = useState(false);

  // Alert box code starts
  const [openAlertBox, setOpenAlertBox] = useState(false);
  const handleOpenAlertBox = () => setOpenAlertBox(true);
  const handleCloseAlertBox = () => setOpenAlertBox(false);

  const [alertMsg, setAlertMsg] = useState('');
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  // Alert box code ends

  const handleOpen = async (userMailId, status, organization) => {
    try {
      const response = await admitIbpsUser({
        variables: {
          email: userMailId,
        },
      });
      setAlertMsg(response.data.ibpsAdmittion.message);
      handleOpenAlertBox();
      setTimeout(usersRefetch);
    } catch (error) {
      setAlertMsg(error.message);
      handleOpenAlertBox();
      handleClose();
      navigate('/dashboard');
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  function RenderStatus(props) {
    const { isAdmitted } = props;

    return (
      <div style={{ width: '150px' }}>
        <Typography
          className={classes.regStatus}
          style={
            isAdmitted
              ? { border: '1px solid #00800026', backgroundColor: '#00800026' }
              : {
                  border: '1px solid rgb(0 10 128 / 17%)',
                  backgroundColor: 'rgb(235 12 12 / 79%)',
                }
          }
        >
          {isAdmitted ? 'Admitted' : 'Not admitted'}
        </Typography>
      </div>
    );
  }
  const totlaAdmitted = approvedUsers?.filter((user) => user.is_admitted);

  return (
    <>
      <>
        <Modal open={open} onClose={handleClose} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
          <Box sx={style}></Box>
        </Modal>

        <Modal open={openAlertBox} onClose={handleCloseAlertBox} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
          <Box sx={style}>
            <div style={{ textAlign: 'right' }}>
              <button aria-label='close' onClick={() => handleCloseAlertBox()} className={classes.closeButton}></button>
            </div>

            <Typography
              id='modal-modal-description'
              sx={{
                fontSize: '25px',
                // fontFamily: 'HelveticaforTargetBold',
                textAlign: 'center',
              }}
            >
              {alertMsg}
            </Typography>
          </Box>
        </Modal>

        <Grid
          container
          style={{ marginTop: '10px' }}
          className={classes.dataGridContainer}
          sx={{
            '& .MuiBox-root': {
              // border: '1px solid green',
              padding: 0,
            },
          }}
        >
          <Box sx={{ width: '100%', marginTop: '20px !important' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', textAlign: 'left' }}>
              <h3 style={{ color: '#CC0000' }}>OBS&D Dashboard</h3>
            </Box>
            <div style={{ display: 'flex' }}>
              <div style={{ display: 'flex', marginRight: '20px' }}>
                <h3>Total Registered: {approvedUsers?.length}</h3>
              </div>
              <div item>
                <h3>Total Admitted: {totlaAdmitted?.length}</h3>
              </div>
            </div>
            <Grid item sm={12} style={{ marginTop: '15px' }}>
              <MuiDataTable
                data={approvedUsers}
                columns={columns}
                options={{
                  selectableRows: 'none',
                  responsive: 'standard',
                  searchAlwaysOpen: true,
                  searchPlaceholder: 'search by name/email/phone number',
                  viewColumns: false,
                  filter: false,
                  print: false,
                  download: false,
                  customRowRender: (data) => {
                    const [company, first_name, email, contct_no, isAdmitted] = data;

                    return (
                      <tr key={email}>
                        <td>{company}</td>
                        <td style={{ wordBreak: 'break-all' }}>{first_name}</td>
                        <td>{email}</td>
                        <td>{contct_no ? contct_no : 'NA'}</td>
                        <td>
                          <RenderStatus isAdmitted={isAdmitted} />
                        </td>
                        <td>
                          <Button variant='contained' onClick={() => handleOpen(email, isAdmitted, company)} disabled={isAdmitted}>
                            ADMIT
                          </Button>
                        </td>
                      </tr>
                    );
                  },
                }}
              />
            </Grid>
          </Box>
        </Grid>
      </>
    </>
  );
};
export default Dashboard;
