import { Dialog, DialogContent } from "@mui/material";
import { withStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
// import { TickMark, crossSvg } from '../../Images/Images';
import { whiteCrossSvg } from "../../assets/images/Images";
// import crossSvg from '../../Images/white_cross.svg';

const styles = {
  dialog: {
    "& .MuiDialog-paper": {
      maxWidth: 479,
      borderRadius: "25px !important",
      // backgroundImage: `url(${popUpBgImage}) !important`,
      backgroundColor: "#000000 !important",
      backgroundPosition: "bottom !important",
      backgroundRepeat: "no-repeat !important",
      // border: '1px solid #eb0000 !important',
      backgroundSize: "100% 30% !important",
    },
  },
  closeButton: {
    border: "none",
    width: "20px",
    height: "20px",
    padding: 0,
    background: `url(${whiteCrossSvg}) no-repeat`,
    "&:focus-visible": {
      //background: '#D6D6D6',
      outline: "2px dotted #666666",
      outlineOffset: 2,
    },
    "&:hover": {
      opacity: 0.75,
    },
  },
  crossMark: {
    height: 17,
    width: 17,
    backgroundColor: "#ffffff",
    "&:hover": {
      opacity: 0.75,
      backgroundColor: "#fff",
    },
  },
  signupSuccessDialog: {
    padding: "25px 24px 85px !important",
  },
};

const Popup = ({
  classes,
  handleClose,
  open,
  existingUser,
  isRegError,
  updateYtrUserResponse,
  registerUserResponse,
  unsubscribeUserResponse,
}) => {
  const navigate = useNavigate();
  return (
    <Dialog
      open={open}
      keepMounted
      onClose={() => handleClose()}
      aria-describedby="signup_success"
      className={classes.dialog}
      PaperProps={{
        style: {
          // borderRadius: 25,
          // backgroundImage: `url(${popUpBgImage})`,
          // backgroundColor: '#000000',
          // backgroundPosition: 'bottom',
          // backgroundRepeat: 'no-repeat',
          // border: '1px solid #eb0000',
          // backgroundSize: '100% 30%',
        },
      }}
    >
      <div style={{ textAlign: "right", padding: "5% 10% 0 0" }}>
        <button aria-label="close" onClick={() => handleClose()} className={classes.closeButton}></button>
      </div>
      <DialogContent className={classes.signupSuccessDialog}>
        <div style={{ width: "100%" }}>
          {(updateYtrUserResponse?.status === "REGISTERED" ||
            registerUserResponse?.status === "Added participant to the event") && (
            <div style={{ paddingTop: 0 }}>
              <div style={{ fontSize: 18, textAlign: "center", color: "#FFF" }}>
                <b>Thanks for registering! </b> <br /> This is an exclusive,invite-only event.
                <br />
                You will receive an email with further information <br />
                about your participation. <br />
                <br />
                If you have any questions, drop us a line at <br /> meetup@target.com
              </div>
            </div>
          )}

          {updateYtrUserResponse?.status === "APPROVED" && (
            <div style={{ paddingTop: 19 }}>
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: 25,
                  textAlign: "center",
                  color: "#fff",
                }}
              >
                Thanks for updating your information.
              </div>
              <div style={{ fontSize: 20, textAlign: "center", color: "#fff" }}>
                {`We've already saved a seat for you. Check your Inbox for details soon and head to our `}
                <span style={{ fontWeight: "bold", cursor: "pointer" }} onClick={() => navigate("/gallery")}>
                  Gallery
                </span>
                {` page for glimpses of past editions.`}
              </div>
            </div>
          )}

          {unsubscribeUserResponse?.status === "success" && (
            <div style={{ paddingTop: 0 }}>
              <div style={{ fontSize: 18, textAlign: "center", color: "#FFF" }}>Thank you for your response</div>
            </div>
          )}
          {isRegError && (
            <div style={{ paddingTop: 0 }}>
              <div style={{ fontSize: 18, textAlign: "center", color: "#FFF" }}>
                Error while saving your information.
                <br />
                Please write to meetup@target.com
              </div>
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(styles)(Popup);
