import { useEffect, useState } from 'react';
import { Typography, Grid, useTheme } from '@mui/material';
// import { tandcLeftImg, tandcRightImg } from '../Images/Images';
import { tAndcBg } from '../Images/Images';
import DottedWebBack from '../Images/DottedWebBack.png';
import { Helmet } from 'react-helmet';

const styles = (theme) => {
  return {
    tcContainer: {
      [theme.breakpoints.down('md')]: {
        padding: '20px',
        marginTop: '10%',
      },
      marginTop: '5%',
      backgroundImage: `url(${tAndcBg})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center right',
      backgroundSize: '50%',
      backgroundColor: '#000000',
      color: '#fff',
      textAlign: 'left',
    },
    tcRightImg: {
      position: 'absolute',
      right: 0,
      top: '366px',
      width: '130px',
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    tcLeftImg: {
      backgroundImage: 'none',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100px',
      backgroundPosition: 'bottom left',
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    headings: {
      paddingTop: '30px',
      paddingBottom: '10px',
      fontSize: '20px !important',
      fontWeight: 'bold !important',
      [theme.breakpoints.down('md')]: {
        //fontSize: '12px !important',
      },
      //   fontFamily: 'HelveticaforTargetBold !important',
    },
    tcTitle: {
      fontSize: '55px !important',
      //   fontFamily: 'HelveticaforTargetBold !important',
      padding: '40px 0',
      marginTop: '60px !important',
      fontWeight: '400 !important',
      [theme.breakpoints.down('md')]: {
        fontSize: '28px !important',
      },
    },
    tcContent: {
      paddingBottom: 40,
      lineHeight: 1.6,
      position: 'relative',
      //   fontFamily: 'HelveticaforTarget-Light',
      fontSize: '20px',
    },
    whiteLine: {
      width: '100px',
      display: 'block',
      border: '2px solid #FFF',
      backgroundColor: '#FFF',
      marginTop: '20px',
      marginBottom: '1rem',
      [theme.breakpoints.down('md')]: {
        // width: '50px',
      },
    },
  };
};
const TermsAndConditions = () => {
  //   const styles(theme) = useStyles();
  const [tabIndex, setTabIndex] = useState(-1);
  const theme = useTheme();
  useEffect(() => {
    window.scrollTo(0, 0);
    setTabIndex(1);
  }, []);
  return (
    <>
      <Helmet>
        {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
        <title>{'Target MeetUp - Terms & Conditions'}</title>
        <script async src='https://www.googletagmanager.com/gtag/js?id=G-SRT1SJE7W0'></script>
        <script>
          {`  window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());

                        gtag('config', 'G-SRT1SJE7W0');`}
        </script>
        {/* <!-- Google Tag Manager (noscript) --> */}
        <noscript>
          {`
                    <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WLZJSJZ"
                    height="0" width="0" style="display:none;visibility:hidden"></iframe>`}
        </noscript>
        {/* <!-- End Google Tag Manager (noscript) --> */}
      </Helmet>
      <Grid container sx={styles(theme).tcContainer}>
        <Grid item md={2} sx={styles(theme).tcLeftImg}>
          {/* <img src={tandcLeftImg} alt='' style={styles(theme).tcLeftImg} role='presentation' /> */}
        </Grid>
        <Grid item md={8}>
          <Typography variant='h3' sx={styles(theme).tcTitle} tabIndex={tabIndex}>
            Terms & Conditions
            {/* <span style={styles(theme).whiteLine}></span> */}
          </Typography>
          {/* <img src={tandcRightImg} alt='' style={styles(theme).tcRightImg} role='presentation' /> */}
          <div style={styles(theme).tcContent}>
            <Typography variant='p'>
              Welcome to multiplai.target.com. These Terms &amp; Conditions apply to the Target website located at{' '}
              <a
                href='https://multiplai.target.com/'
                target='_blank'
                rel='noopener noreferrer'
                style={{
                  color: '#fff',
                  textDecoration: 'none',
                  //   fontFamily: 'HelveticaforTargetBold',
                  fontWeight: 'bold',
                }}
              >
                https://multiplai.target.com
              </a>
              &nbsp;and all other sites, mobile sites, services, applications, platforms and tools where these Terms &amp; Conditions appear or are linked
              (collectively, the "Site"). As used in these Terms & Conditions, "Target", "us" or "we" refers to Target Corporation India Private Limited and its
              subsidiaries and affiliates.
            </Typography>
            <Typography variant='h2' sx={styles(theme).headings}>
              Introduction
            </Typography>
            <Typography variant='p'>
              BY ACCESSING OR OTHERWISE USING THE SITE YOU AGREE TO THESE TERMS & CONDITIONS. Any person or entity who interacts with the Site through the use
              of crawlers, robots, browsers, data mining or extraction tools, or other functionality, whether such functionality is installed or placed by such
              person or entity or a third party, is considered to be using the Site. If at any time you do not accept all of these Terms & Conditions, you must
              immediately stop using the Site. Certain areas within the Site may be governed by additional terms and policies ("Additional Terms"). By using
              those areas of the Site, you agree to the Additional Terms. The Additional Terms are incorporated into these Terms & Conditions, and any reference
              to these Terms & Conditions includes the Additional Terms.
            </Typography>
            <Typography variant='h2' sx={styles(theme).headings}>
              Content
            </Typography>
            <Typography variant='p'>
              All content included on the Site such as text, graphics, logos, images, audio clips, video, data, music, software, application updates, and other
              material (collectively "Content") is owned or licensed property of Target or its suppliers or licensors, and is protected by copyright, trademark,
              patent or other proprietary rights. The collection, arrangement and assembly of all Content on the Site is the exclusive property of Target
              Brands, Inc. and protected by U.S., Indian and international copyright laws. Target and its suppliers, partners and licensors, expressly reserve
              all intellectual property rights in all Content.
            </Typography>
            <Typography variant='h2' sx={styles(theme).headings}>
              License and Access
            </Typography>
            <Typography variant='p'>
              Target grants you a limited license to access and make personal use of the Site and the Content for NONCOMMERCIAL PURPOSES ONLY and only to the
              extent such use does not violate these Terms & Conditions including, without limitation, the prohibitions listed in the "UNLAWFUL OR PROHIBITED
              USES" section of these Terms & Conditions. Accessing, downloading, printing, posting, storing or otherwise using the Site or any of the Content
              for any commercial purpose, whether on behalf of yourself or on behalf of any third party, constitutes a material breach of these Terms &
              Conditions. The Site is intended for use by individuals 13 years of age or older. If you are under 18, you may use this Site only with involvement
              of a parent or guardian. Unlawful or Prohibited Uses The Site may only be used for lawful purposes in accordance with the terms of the license
              granted in these Terms & Conditions. As a condition of your use of this Site, you warrant to Target that you will not use the Site for any purpose
              that is unlawful or prohibited by these Terms & Conditions. Whether on behalf of yourself or on behalf of any third party, YOU MAY NOT:
            </Typography>
            <ul>
              <li>
                Make any commercial use of the Site or its Content, including making any collection or use of any product listings, descriptions, prices or
                images;
              </li>
              <li>Download, copy or transmit any Content for the benefit of any other merchant;</li>
              <li>
                Use or attempt to use any engine, software, tool, agent, data or other device or mechanism (including browsers, spiders, robots, avatars or
                intelligent agents) to navigate or search the Site other than the search engine and search agents provided by Target or generally publicly
                available browsers;
              </li>
              <li>Frame, mirror or use framing techniques on any part of the Site without Target’s express prior written consent;</li>
              <li>
                Make any use of data extraction, scraping, mining or other data gathering tools, or create a database by systematically downloading or storing
                Site content, or otherwise scrape, collect, store or use any Content, product listings, descriptions, prices or images, except pursuant to the
                limited license granted by these Terms &amp; Conditions;
              </li>
              <li>Use any meta tags or any other hidden text utilizing Target’s name or marks;</li>
              <li>
                Misrepresent the identity of a user, impersonate any person or entity, falsely state or otherwise misrepresent your affiliation with any person
                or entity in connection with the Site, or express or imply that we endorse any statement you make;
              </li>
              <li>Use a buying agent to conduct transactions on the Site;</li>
              <li>Conduct fraudulent activities on the Site;</li>
              <li>
                Violate or attempt to violate the security of the Site, including, without limitation: (i) accessing data not intended for you or logging onto a
                server or an account that you are not authorized to access; (ii) trying to change the behavior of the Site; (iii) attempting to probe, scan or
                test the vulnerability of a system or network, or to breach security or authentication measures; (iv) attempting to interfere with service to
                any user, host or network, including, without limitation, via means of submitting malware to the Site, overloading, "flooding," "spamming,"
                "mailbombing" or "crashing"; (v) forging any header or any part of the header information in any email or posting; or (vi) forging
                communications on behalf of the Site (impersonating the Target Site) or to the Site (impersonating another user);
              </li>
              <li>
                Tamper with the Site or use or attempt to use any device, software, routine or data that interferes or attempts to interfere with the working or
                functionality of the Site or any activity being conducted on the Site;
              </li>
              <li>
                Use the Site to defame, abuse, harass, stalk, threaten or otherwise violate the legal rights of others, including others’ privacy rights or
                rights of publicity;
              </li>
              <li>Harvest or collect personally identifiable information about other users of the Site;</li>
              <li>Restrict or inhibit any other person from using the Site (including, without limitation, by hacking or defacing any portion of the Site);</li>
              <li>Use the Site to advertise or offer to sell or buy any goods or services without Target’s express prior written consent;</li>
              <li>Reproduce, duplicate, copy, sell, resell or otherwise exploit for any commercial purposes any portion of, use of, or access to the Site;</li>
              <li>Modify, adapt, translate, reverse engineer, decompile or disassemble any portion of the Site; or</li>
              <li>Remove any copyright, trademark or other proprietary rights notice from the Site or materials originating from the Site.</li>
            </ul>
            <Typography variant='h2' sx={styles(theme).headings}>
              Your Account
            </Typography>
            <Typography variant='p'>
              You may be required to register with Target in order to access certain services or areas of the Site. With respect to any such registration, we
              may refuse to grant to you the user name you request. Your user name and password are for your personal use only. If you use the Site, you are
              responsible for maintaining the confidentiality of your account and password and for restricting access to your computer, and you agree to accept
              responsibility for all activities that occur under your account or password. In addition to all other rights available to Target including those
              set forth in these Terms & Conditions, Target reserves the right, in its sole discretion, to terminate your account, refuse service to you.
            </Typography>
            <Typography variant='h2' sx={styles(theme).headings}>
              Modification & Termination
            </Typography>
            <Typography variant='p'>
              Target may at any time: (i) modify or discontinue any part of the Site; (ii) charge, modify or waive fees required to use the Site; or (iii) offer
              opportunities to some or all Site users. Target reserves the right to make changes to these Terms & Conditions at any time, and such changes will
              be effective immediately upon being posted on the Site. Each time you use the Site, you should review the current Terms & Conditions. Your
              continued use of the Site will indicate your acceptance of the current Terms & Conditions; however, any change to these Terms & Conditions after
              your last usage of the Site will not be applied retroactively. Target reserves the right, without notice and at its sole discretion, to terminate
              your account or your use of the Site and to block or prevent future access to and use of the Site (i) if you violate any of these Terms &
              Conditions, (ii) for any other reason or (iii) for no reason. Upon any such termination, your right to use the Site will immediately cease. You
              agree that we shall not be liable to you or any third party for any termination of your access to the Site. Upon termination, all provisions of
              these Terms & Conditions which are by their nature intended to survive termination, all representations and warranties, all limitations of
              liability and all indemnities shall survive such termination.
            </Typography>
            <Typography variant='h2' sx={styles(theme).headings}>
              Disclaimers of Warranties
            </Typography>
            <Typography variant='p'>
              Target cannot and does not represent or warrant that the Site or its server will be error-free, uninterrupted, free from unauthorized access
              (including third-party hackers or denial of service attacks), or otherwise meet your requirements. THE SITE AND ALL INFORMATION, CONTENT,
              MATERIALS , PRODUCTS, SERVICES, AND USER CONTENT INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE SITE (COLLECTIVELY, THE "SITE
              CONTENTS") ARE PROVIDED BY TARGET ON AN "AS IS," "AS AVAILABLE" BASIS, WITHOUT REPRESENTATIONS OR WARRANTIES OF ANY KIND. TARGET MAKES NO
              REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THE SITE, THE ACCURACY OR COMPLETENESS OF THE SITE CONTENTS,
              OR THAT EMAILS SENT FROM TARGET ARE FREE OF MALWARE OR OTHER HARMFUL COMPONENTS. YOU EXPRESSLY AGREE THAT YOUR USE OF THE SITE IS AT YOUR SOLE
              RISK. TO THE FULL EXTENT PERMITTED BY LAW, TARGET DISCLAIMS ANY AND ALL REPRESENTATIONS AND WARRANTIES WITH RESPECT TO THE SITE AND THE SITE
              CONTENTS, WHETHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, WARRANTIES OF TITLE, MERCHANTABILITY, AND FITNESS FOR A PARTICULAR PURPOSE OR
              USE. On the Site, we may display names, marks, products, advertisements, or services of third parties, pop-up texts, or links to third-party
              sites. If you decide to link to any such third-party sites, you do so entirely at your own risk.
            </Typography>
            <Typography variant='h2' sx={styles(theme).headings}>
              Limitation of Liability
            </Typography>
            <Typography variant='p'>
              UNDER NO CIRCUMSTANCES SHALL TARGET OR ITS EMPLOYEES, DIRECTORS, OFFICERS OR AGENTS BE LIABLE FOR ANY DIRECT OR INDIRECT LOSSES OR DAMAGES ARISING
              OUT OF OR IN CONNECTION WITH YOUR USE OF OR INABILITY TO USE THE SITE. THIS IS A COMPREHENSIVE LIMITATION OF LIABILITY THAT APPLIES TO ALL LOSSES
              AND DAMAGES OF ANY KIND (WHETHER GENERAL, SPECIAL, CONSEQUENTIAL, INCIDENTAL, EXEMPLARY OR OTHERWISE, INCLUDING, WITHOUT LIMITATION, LOSS OF DATA,
              INCOME OR PROFITS), WHETHER IN CONTRACT OR TORT, EVEN IF TARGET HAS BEEN ADVISED OF OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES. IF
              YOU ARE DISSATISFIED WITH THE SITE, ANY CONTENT ON THE SITE, OR THESE TERMS & CONDITIONS, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING
              THE SITE. YOU ACKNOWLEDGE, BY YOUR USE OF THE SITE, THAT YOUR USE OF THE SITE IS AT YOUR SOLE RISK. EACH PROVISION OF THESE TERMS & CONDITIONS
              THAT PROVIDES FOR A LIMITATION OF LIABILITY, DISCLAIMER OF WARRANTIES, OR EXCLUSION OF DAMAGES IS TO ALLOCATE THE RISKS UNDER THE AGREEMENT
              BETWEEN YOU AND TARGET. THIS ALLOCATION IS AN ESSENTIAL ELEMENT OF THE BASIS OF THE BARGAIN BETWEEN YOU AND TARGET. THE LIMITATIONS IN THIS
              SECTION WILL APPLY EVEN IF ANY LIMITED REMEDY FAILS OF ITS ESSENTIAL PURPOSE(S).
            </Typography>
            <Typography variant='h2' sx={styles(theme).headings}>
              Indemnification & Defense
            </Typography>
            <Typography variant='p'>
              As a condition of the use of the Site, you agree to defend, indemnify and hold harmless Target and its respective employees, directors, officers,
              agents, vendors and suppliers from and against any liabilities, losses, investigations, inquiries, claims, suits, damages, costs and expenses
              (including, without limitation, reasonable attorneys’ fees and expenses) (each, a "Claim") arising out of or otherwise relating to Claims alleging
              facts that if true would constitute a breach by you of these Terms & Conditions, or any User Content submitted by you.
            </Typography>
            <Typography variant='h2' sx={styles(theme).headings}>
              Governing Law & Jurisdiction
            </Typography>
            <Typography variant='p'>
              Any disputes relating to the Content or otherwise relating to this Site shall be governed by the laws of India to the exclusive jurisdiction of
              Courts at Bangalore.
            </Typography>
            <Typography variant='h2' sx={styles(theme).headings}>
              Privacy
            </Typography>
            <Typography variant='p'>
              Any personal data you transmit to the Site by electronic mail or otherwise, will be used by Target in accordance with the Privacy Policy displayed
              on this Site.
            </Typography>
            <Typography variant='h2' sx={styles(theme).headings}>
              Links
            </Typography>
            <Typography variant='p'>
              Target disclaims all warranties with regard to the content of or otherwise relating to other web sites or resources linked in any way to this
              Site. Any links to a third-party site are provided for convenience only. Target is not responsible for and makes no representations concerning the
              condition and content of, or products offered at the third-party site. A link does not indicate, expressly or impliedly, that Target endorses the
              site, or the products or services offered there. Your access to any linked site and use of any products and services offered there is at your own
              risk.
            </Typography>
            <Typography variant='h2' sx={styles(theme).headings}>
              Miscellaneous
            </Typography>
            <Typography variant='p'>
              These Terms & Conditions, including policies and information linked from or incorporated herein or otherwise found on the Site, constitute the
              entire agreement between you and Target with respect to the Site and supersede all prior or contemporaneous communications, agreements, and
              proposals with respect to the Site. No provision of these Terms & Conditions shall be waived except pursuant to a writing executed by the party
              against whom the waiver is sought. No failure to exercise, partial exercise of, or delay in exercising any right or remedy under these Terms &
              Conditions shall operate as a waiver or estoppel of any right, remedy, or condition. If any provision of these Terms & Conditions is held invalid,
              illegal or unenforceable, the validity, legality and enforceability of the remaining provisions will not be affected or impaired. You may not
              assign, transfer, or sublicense any of your rights or obligations under these Terms & Conditions without our express prior written consent. We
              will not be responsible for failure to fulfill any obligation due to causes beyond our control. By using this site, you are acknowledging your
              consent to the foregoing terms and conditions. If you do not agree to these terms and conditions, do not use this site.
            </Typography>
          </div>
        </Grid>
        <Grid item md={2}></Grid>
        <Grid container lg={12} style={{ marginBottom: '-7px' }}>
          <img src={DottedWebBack} width='100%' alt='' style={{ marginTop: '70px' }} />
        </Grid>
      </Grid>
    </>
  );
};

export default TermsAndConditions;
