import { Typography, Grid, useTheme } from '@mui/material';
import { privacyPolicyBg } from '../Images/Images';
import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
const styles = (theme) => {
  return {
    privacyMainContainer: {
      backgroundImage: `url(${privacyPolicyBg})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'bottom right',
      backgroundSize: '60%',
      // backgroundSize: '100%',
      backgroundColor: '#000000',
      color: '#fff',
      minHeight: '90vh',
    },
    headings: {
      paddingTop: '30px',
      paddingBottom: '10px',
      fontSize: '20px !important',
      fontWeight: 'bold !important',
      [theme.breakpoints.down('md')]: {
        //fontSize: '12px !important',
      },
      //   fontFamily: 'HelveticaforTargetBold !important',
    },
    privacyContainer: {
      marginTop: '5%',
      paddingBottom: '100px',
      textAlign: 'left',
      [theme.breakpoints.down('md')]: {
        backgroundImage: 'none',
        //textAlign: 'center',
        paddingBottom: '50px',
        padding: '20px',
        marginTop: '10%',
      },
    },
    title: {
      padding: '40px 0',
      //   fontFamily: 'HelveticaforTargetBold !important',
      fontWeight: 'bold !important',
      fontSize: '55px !important',
      marginTop: '60px !important',
    },

    description: {
      //   fontFamily: 'HelveticaforTarget-Light',
      fontSize: '20px',
    },
    whiteLine: {
      width: '90px',
      display: 'block',
      border: '2px solid #FFF',
      backgroundColor: '#FFF',
      marginTop: '20px',
      marginBottom: '1rem',
      [theme.breakpoints.down('md')]: {
        // width: '50px',
      },
    },
    privacyMailLink: {
      color: '#fff',
      textDecoration: 'none',
      //   fontFamily: 'HelveticaforTargetBold',
      fontWeight: 'bold !important',
    },
  };
};
const PrivacyPolicy = () => {
  //   const classes = useStyles();
  const theme = useTheme();
  const [tabIndex, setTabIndex] = useState(-1);
  // const desktop = useMediaQuery((theme) => theme.breakpoints.up('md'));
  useEffect(() => {
    window.scrollTo(0, 0);
    setTabIndex(1);
  }, []);
  return (
    <div style={styles(theme).privacyMainContainer}>
      <Helmet>
        {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
        <title>Target MeetUp - Privacy Policy</title>
        <script async src='https://www.googletagmanager.com/gtag/js?id=G-SRT1SJE7W0'></script>
        <script>
          {`  window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());

                        gtag('config', 'G-SRT1SJE7W0');`}
        </script>
        {/* <!-- Google Tag Manager (noscript) --> */}
        <noscript>
          {`
                    <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WLZJSJZ"
                    height="0" width="0" style="display:none;visibility:hidden"></iframe>`}
        </noscript>
        {/* <!-- End Google Tag Manager (noscript) --> */}
      </Helmet>
      <Grid container sx={styles(theme).privacyContainer}>
        <Grid item md={2}></Grid>
        <Grid item md={8}>
          <Typography variant='h3' sx={styles(theme).title} tabIndex={tabIndex}>
            Privacy Policy
            {/* <span className={classes.whiteLine} aria-hidden={true}></span> */}
          </Typography>
          <Typography variant='h2' sx={styles(theme).headings}>
            Your Account
          </Typography>
          <Typography variant='p'>
            Target Corporation India Private Limited is an affiliate of Target Corporation Inc., hereinafter collectively known as “Target” or “We” or “Our” or
            “Us”. Target and all its affiliates respect and uphold your rights to privacy protection in respect of the collection, use, disclosure and handling
            of personal information. Target will handle your personal information in accordance with applicable law. This Policy details the practices we have
            adopted to protect your privacy, so that you can feel confident about how we manage the personal information you entrust to us.
          </Typography>
          <Typography variant='h2' sx={styles(theme).headings}>
            When and how do we collect personal information?
          </Typography>
          <Typography variant='p' sx={styles(theme).description}>
            Personal information is any information that can be used to identify you. We may collect information from you including your personal information
            (such as name, e-mail, phone number, date of birth and postal address) in the following ways: 1. through our website; 2. when you register for the
            event to customize the event content, send emails about the event and perform analytics 3. when you contact our Team in order to make a inquiry
            about the event or a particular service;
          </Typography>
          <Typography variant='h2' sx={styles(theme).headings}>
            What do we do with the personal information?
          </Typography>
          <Typography variant='p' sx={styles(theme).description}>
            Our primary goal in collecting personal information is to ensure that you are satisfied with the event and our services. Your personal information
            is never shared, sold, rented or otherwise disclosed outside the company (except as required for any statutory or legal requirements) in ways
            different from what is described in this Privacy Policy. We will take all reasonable steps to maintain the security and integrity of your personal
            information including the use of computer access passwords, personnel policies and firewalls.
          </Typography>
          <Typography variant='h2' sx={styles(theme).headings}>
            Cookies
          </Typography>
          <Typography variant='p' sx={styles(theme).description}>
            Like many companies, we sometimes use "cookie" technology on our websites. These cookies are stored on your computer by your browser. When you log
            in, the cookie tells us whether you've visited us before, or you are a new visitor. The cookie doesn't obtain any personal information about you or
            provide us with any way to contact you and the cookie doesn't extract any information from your computer. We use the cookie to help us identify
            website features in which you have the greatest interest, so that we can provide more of what you want.
          </Typography>
          <Typography variant='h2' sx={styles(theme).headings}>
            Links
          </Typography>
          <Typography variant='p' sx={styles(theme).description}>
            The website operated by Target may contain links to other sites operated by third parties. Target makes no representations or warranties as to the
            privacy practices of any third-party site and is not responsible for the Privacy Policies of other sites.
          </Typography>
          <Typography variant='h2' sx={styles(theme).headings}>
            Information collected through Registration Form:
          </Typography>
          <Typography variant='p' sx={styles(theme).description}>
            Target will use the details you provide by you when you register for the event (whether by e-mail, telephone, letter or fax) in order to provide you
            with the information, services and products necessary to satisfy you. This may involve sending information sheet or follow-up letter in relation to
            the event, your inquiry or making a follow-up call. Your personal data is stored in a database and we also keep the records of all correspondence
            that is sent to you.
          </Typography>
          <Typography variant='h2' sx={styles(theme).headings}>
            Information collected through Website
          </Typography>
          <Typography variant='p' sx={styles(theme).description}>
            Target will use the personal information you supply to us via registration forms, survey forms, questionnaires and through our websites for the
            purpose of providing you with information and services through various channels viz. Calls/SMS/WhatsApp/ Emails. Any personal information you
            provide to us may be entered into a database, recorded in spreadsheets and may be kept in hard copy format. That information may be used for future
            promotional, marketing, market research, product development and publicity purposes via various channels. We may share your personal information
            between our different departments and with third parties such as fulfilment houses and advertising agencies who assist us with our promotional,
            marketing and research activities. We impose obligations on any external organizations with which we share your personal information to maintain the
            integrity and security of that information.
          </Typography>
          <Typography variant='h2' sx={styles(theme).headings}>
            Questions on privacy policy?
          </Typography>
          <Typography variant='p' sx={styles(theme).description}>
            If you have any questions about our Privacy Policy or concerns, please contact multiplai@target.com
          </Typography>
          <Typography variant='h2' sx={styles(theme).headings}>
            What if our privacy policy changes?
          </Typography>
          <Typography variant='p' sx={styles(theme).description}>
            We will use information in accordance with the Privacy Policy under which the information was collected. If we decide to change our Privacy Policy,
            we will post those changes on our websites, so you are always aware of what information we collect, how we use it, and under what circumstances, if
            any, we disclose. The privacy policy is in accordance with the applicable laws and Target has the right to change/amend the policy from time to
            time, in line with the applicable laws.
          </Typography>
          {/* <Typography variant='p' className={classes.description}>
                      Target needs to collect, use, retain and share personal information that you provide when you register for the event and access the event
                      app to customize the app content, send conference kits & emails about the event and perform analytics. The personal information may
                      include, for example, name, contact information, and employment information. Target will handle your personal information in accordance
                      with applicable law. Your personal information may be disclosed to third parties, including service providers who assist with the event.
                      By providing your personal information, you acknowledge and consent to its collection, use, retention and sharing for the stated purposes.
                      If you have privacy questions or concerns, please contact{' '}
                      <a href='mailto:multiplai@target.com' className={classes.privacyMailLink}>
                          multiplai@target.com
                      </a>
                      , attention: Privacy Manager
                  </Typography> */}
        </Grid>
        <Grid item md={2}></Grid>
      </Grid>
      {/* <Grid container>
                {desktop ? (
                    <Grid item md={12} style={{ marginBottom: '-7px' }}>
                        <img src={privacyBgImg} width='30%' alt='' style={{ position: 'absolute', bottom: '0' }} />
                    </Grid>
                ) : null}
            </Grid> */}
    </div>
  );
};

export default PrivacyPolicy;
