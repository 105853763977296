import { useEffect, useState } from "react";

/* eslint-disable no-useless-escape */
export function IsEmail(email) {
  let regex = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  if (!regex.test(email)) {
    return false;
  } else {
    return true;
  }
}

export function IsValidFirstName(param) {
  let regex = /^(?=.{1,50}$)[a-zA-Z ]+(?:['_.\\s][a-zA-Z]+)*$/;
  if (!regex.test(param)) {
    return false;
  } else {
    return true;
  }
}

//validation check for last name and company name
export function IsValidLastName(param) {
  let regex = /^(?=.{1,50}$)[a-zA-Z ]+(?:['_.\\s][a-zA-Z]+)*$/;
  if (!regex.test(param)) {
    return false;
  } else {
    return true;
  }
}

export function IsValidDesignation(param) {
  let regex = /^(?=.{1,50}$)[a-zA-Z ]+(?:['_.\\s][a-zA-Z]+)*$/;
  if (!regex.test(param)) {
    return false;
  } else {
    return true;
  }
}

export function IsValidLinkedInProfile(param) {
  let regex = /^(?:http(s)?:\/\/)?(www\.)?linkedin\.com\/in\/[a-zA-Z0-9-]+$/;
  if (!regex.test(param)) {
    return false;
  } else {
    return true;
  }
}
// validation check for additional details, landmark and about user
export function IsValidAddress(param) {
  let regex = /^[\\.0-9a-zA-Z\s,\- ]+$/;
  if (!regex.test(param)) {
    return false;
  } else {
    return true;
  }
}

//validation check for houseNo
export function IsValidHouseNo(param) {
  let regex = /^[\\.0-9a-zA-Z\s,\- ]+$/;
  if (!regex.test(param)) {
    return false;
  } else {
    return true;
  }
}

export function IsValidCity(param) {
  let regex = /^[a-zA-Z\\s\ ]+$/;
  if (!regex.test(param)) {
    return false;
  } else {
    return true;
  }
}

export function IsValidpincode(param) {
  let regex = /^[1-9][0-9]{5}$/;
  if (!regex.test(param)) {
    return false;
  } else {
    return true;
  }
}

export function IsValidBio(params) {
  let regex = /^[\.0-9a-zA-Z\s\,\-\ ]+$/;
  if (!regex.test(params)) {
    return false;
  } else {
    return true;
  }
}

export function IsValidMobileNo(params) {
  let regex = /^[6-9][0-9]{9}$/;
  console.log("params ", !regex.test(params), params.includes("XXXXXX"));
  if (params.includes("XXXXXX")) {
    return true;
  } else if (!regex.test(params)) {
    return false;
  } else {
    return true;
  }
}

export function IsValidPassword(params) {
  let regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
  if (!regex.test(params)) {
    return false;
  } else {
    return true;
  }
}

export function getDateDiff(modified_at) {
  const modified_date = new Date(modified_at);
  const current_date = new Date();
  var diff = (modified_date.getTime() - current_date.getTime()) / 1000;
  diff /= 60 * 60 * 24 * 7 * 4;
  return Math.abs(Math.round(diff));
}

export function getDesignationAndOrg(org, designation) {
  if (designation && org) {
    return designation + ", " + org;
  } else if (designation) {
    return designation;
  } else {
    return org;
  }
}

export default function useOnScreen(ref) {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting));

  useEffect(() => {
    observer.observe(ref.current);
    // Remove the observer as soon as the component is unmounted
    // return () => {
    //   observer.disconnect();
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isIntersecting;
}
