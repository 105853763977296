import { Dialog, DialogContent } from '@mui/material';
// import { withStyles } from "@mui/styles";
import { TickMark, crossSvg } from '../../Images/Images';

const styles = {
  dialog: {
    '& .MuiDialog-paper': {
      maxWidth: 479,
    },
  },
  closeButton: {
    border: 'none',
    width: '20px',
    height: '20px',
    background: `url(${crossSvg}) no-repeat`,
    '&:focus-visible': {
      //background: '#D6D6D6',
      outline: '2px dotted #666666',
      outlineOffset: 2,
    },
    '&:hover': {
      opacity: 0.75,
    },
  },
  crossMark: {
    height: 17,
    width: 17,
    backgroundColor: '#ffffff',
    '&:hover': {
      opacity: 0.75,
      backgroundColor: '#fff',
    },
  },
};

const Popup = ({ handleClose, open, message = '' }) => {
  return (
    <Dialog
      open={open}
      keepMounted
      onClose={() => handleClose()}
      aria-describedby='signup_success'
      className={styles.dialog}
      PaperProps={{
        style: { borderRadius: 25, width: '100%' },
      }}
    >
      <div style={{ textAlign: 'right', padding: '10% 10% 0 0' }}>
        <button aria-label='close' onClick={() => handleClose()} className={styles.closeButton}></button>
      </div>
      <DialogContent style={{ padding: 0 }}>
        <div style={{ width: '100%', height: 320 }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              paddingTop: 20,
            }}
          >
            <img src={TickMark} alt='Logout Success' height={130} width={130} />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              fontSize: 20,
              padding: 20,
              textAlign: 'center',
            }}
          >
            {message}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default Popup;
