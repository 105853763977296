import merge from "lodash/merge";

const commonConfig = {
  api: {
    host: `https://stage-api.target.com/stencil_contents/v1/`,
    key: "ecbac8b7592a44764821a88808bc617698be5b39",
  },
  imagesBucket: "https://gfc.target.com/elevatewebsiteproduction",
  auth: {
    url: `https://oauth.iam.perf.target.com/auth/oauth/v2/authorize?client_id=elevateweb_npe_im&response_type=token id_token&scope=openid profile email&nonce=1234&redirect_uri=${window.location.href}`,
  },
};
const envConfigs = {
  dev: {
    api: {
      host: `https://stage-api.target.com/stencil_contents/v1/`,
      key: "ecbac8b7592a44764821a88808bc617698be5b39",
    },
  },
  stg: {
    api: {
      host: `https://stage-api.target.com/stencil_contents/v1/`,
      key: "ecbac8b7592a44764821a88808bc617698be5b39",
    },
  },
  prod: {
    api: {
      host: `https://api.target.com/stencil_contents/v1/`,
      key: "d8736230ea549457b3682c52be7f738753802f0a",
    },
    auth: {
      url: `https://oauth.iam.target.com/auth/oauth/v2/authorize?client_id=multiplai_prod_im&response_type=token id_token&scope=openid profile email&nonce=1234&redirect_uri=https://meetup.target.com/spot`,
    },
  },
};

const appEnv = process.env.REACT_APP_ENV;
const config = envConfigs[appEnv];

export const envConst = {
  tenantId: "6687b2d17f23f27e604eae21",
};

const apiConfig = merge(commonConfig, config);

export default apiConfig;

if (process.env.REACT_APP_ENV === "dev") {
  console.log(`${appEnv} ENV apiConfig:`, apiConfig);
}
