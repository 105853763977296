import React from "react";
import "./App.css";
import { useLocation } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { indigo, pink } from "@mui/material/colors";
import utilFunction from "./Components/Shared/utilFunction";
import GlobalStyles from "@mui/material/GlobalStyles";
import { BrowserRouter } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import { Box } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
// import { Helmet } from 'react-helmet';

import {
  PageLoader,
  StencilTenant,
  useTenantContext,
  useGetPageContentQuery,
  useGetConfigQuery,
} from "enterprise-stencil";
import { useDispatch } from "react-redux";
import { setEventId, setTypo, useGetActiveEventsQuery } from "./api/tenant";
import Header from "./Components/Layout/Header";
import Footer from "./Components/Layout/Footer";
import Registration from "./Components/Registration/Registration";
import { ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache, concat } from "@apollo/client";
import apiConfig from "./apiConfig";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import TermsAndConditions from "./Pages/TermsAndConditions";
import RetailAgenda from "./Components/Agenda/RetailAgenda";
import IOSAgenda from "./Components/Agenda/IOSAgenda";
import Dashboard from "./Components/Dashboard";
import SupplyChainAgenda from "./Components/Agenda/SupplyChainAgenda";
import AdTechAgenda from "./Components/Agenda/AdTechAgenda";

const customComponents = {
  agendaCustom: RetailAgenda,
  termsconditionCustom: TermsAndConditions,
  privacyCustom: PrivacyPolicy,
  adtechagenda: AdTechAgenda,
  supplyChainAgenda: SupplyChainAgenda,
  iosAgenda: IOSAgenda,
};

// delete this comment

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Container = (props) => {
  const location = useLocation();
  const { tenantId } = useTenantContext();
  const [pageData, setPageData] = React.useState(null);
  const dispatch = useDispatch();
  // var analyticsFlag = location.search.includes('?preview=');

  let query = useQuery();
  const preview = query.get("preview");
  const { data: fetchedData, isLoading } = useGetPageContentQuery(
    {
      tenantId,
      slug: location.pathname.slice(1) ? `/${location.pathname.slice(1)}` : "",
      previewToken: preview,
    },
    { skip: !tenantId }
  );

  React.useEffect(() => {
    setPageData(fetchedData);
  }, [fetchedData]);
  const { data: typoData } = useGetConfigQuery(
    {
      tenantId,
      // slug: location.pathname.slice(1) ? `/${location.pathname.slice(1)}` : '',
      // previewToken: preview,
    },
    { skip: !tenantId }
  );

  React.useEffect(() => {
    if (typoData) {
      console.log("typoData ", typoData);
      const siteTypos = typoData.filter((typo) => typo.name === "typhographies");
      dispatch(setTypo(siteTypos[0].published_value));
    }
  }, [typoData, dispatch]);

  const { data: eventsData, error: eventsError } = useGetActiveEventsQuery({ site_id: tenantId }, { skip: !tenantId });
  React.useEffect(() => {
    console.log("Outside ", eventsData);
    if (eventsData && eventsData.length > 0) {
      console.log("eventsData ", eventsData);
      dispatch(setEventId({ eventId: eventsData[0]._id }));
      localStorage.setItem("eventId", JSON.stringify(eventsData[0]._id));
    }
  }, [dispatch, eventsData, eventsError]);

  return (
    <>
      {/* {!analyticsFlag ? (
        <Helmet> */}
      {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
      {/* <title>MeetUp</title>
          <script async src='https://www.googletagmanager.com/gtag/js?id=UA-177754755-1'></script>
          <script>
            {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'UA-177754755-1');
          `}
          </script> */}
      {/* <!-- Google Tag Manager (noscript) --> */}
      {/* <noscript>
            {`
            <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WLZJSJZ"
            height="0" width="0" style="display:none;visibility:hidden"></iframe>`}
          </noscript> */}
      {/* <!-- End Google Tag Manager (noscript) --> */}
      {/* </Helmet> */}
      {/* ) : (  <title>MeetUp</title>
       )} */}
      <h1 className="visibility-hidden">
        {pageData && pageData.seo && pageData.seo.title
          ? pageData.seo.title
          : pageData && pageData.name
          ? pageData.name
          : ""}
      </h1>
      {isLoading ? (
        <Box sx={{ minHeight: "65vh", width: "100%" }} />
      ) : pageData === undefined ? (
        <div style={{ minHeight: "80vh" }}>
          <p style={{ fontSize: "30px", color: "#fff" }}>404: Page not found</p>
        </div>
      ) : (
        <PageLoader layout={pageData && pageData.layout} isLoading={isLoading} customComponents={customComponents} />
      )}
    </>
  );
};

const styles = {
  background: {
    default: "#fafafa",
    paper: "#ffffff",
  },
  text: {
    primary: "rgba(0, 0, 0, 0.87)",
    secondary: "rgba(0, 0, 0, 0.54)",
  },
  divider: "rgba(0, 0, 0, 0.12)",
};

const fontSettings = {
  typography: {
    fontFamily: "HelveticaForTarget, Helvetica, Arial, sans-serif",
    fontWeightBold: 600,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,

    h3: {
      fontWeight: "700 !important",
    },
    // h4: {
    //   fontWeight: '300 !important',
    // },
  },
  components: {
    MuiCssBaseline: {},
  },
};

let merged = Object.assign({}, fontSettings, styles);

const theme = createTheme({
  palette: {
    primary: indigo,
    secondary: pink,
  },
  ...merged,
});

const Layout = () => {
  return (
    <>
      <header>
        <Header />
      </header>
      <Routes>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/register" element={<Registration />} />
        {/* <Route path='/androidagenda' element={<AndroidAgenda />} /> */}
        {/* <Route path='/iosagenda' element={<IOSAgenda />} /> */}
        {/* <Route path="/agenda" element={<Agenda />} /> */}
        {/* <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/termsandconditions" element={<TermsAndConditions />} /> */}
        <Route path="*" element={<Container />} />

        {/* <Route
          path='/scanforgift'
          element={
            <ProtectedElement>
              <Scanner />
            </ProtectedElement>
          }
        /> */}
      </Routes>
      <footer>
        <Footer />
      </footer>
    </>
  );
};

// const { clientID, authURL } = apiConfig;
function App() {
  const tablet = useMediaQuery(theme.breakpoints.up("sm"));
  const desktop = useMediaQuery(theme.breakpoints.up("md"));
  const variantStyles = {
    "& .article-body": {
      fontSize: 18,
      "& h3 ": {
        fontWeight: 500,
        [theme.breakpoints.up("lg")]: {
          fontSize: utilFunction("h4", "XL", "Desktop", false).sizeVal,
          lineHeight: utilFunction("h4", "XL", "Desktop", false).sizeVal + 3 + "px",
        },
        [theme.breakpoints.down("lg")]: {
          fontSize: utilFunction("h4", "XL", "Tablet").sizeVal,
          lineHeight: utilFunction("h4", "XL", "Tablet").sizeVal + 2 + "px",
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: utilFunction("h4", "XL", "Mobile").sizeVal,
          lineHeight: utilFunction("h4", "XL", "Mobile").sizeVal + 2 + "px",
        },
      },
    },
    // h3 & h4 font sizes
    "& h2.h3-fontxl": {
      [theme.breakpoints.up("lg")]: {
        fontSize: utilFunction("h3", "XL", "Desktop").sizeVal,
        lineHeight: utilFunction("h3", "XL", "Desktop").sizeVal + 2 + "px",
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: utilFunction("h3", "XL", "Tablet").sizeVal,
        lineHeight: utilFunction("h3", "XL", "Tablet").sizeVal + 2 + "px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: utilFunction("h3", "XL", "Mobile").sizeVal,
        lineHeight: utilFunction("h3", "XL", "Mobile").sizeVal + 2 + "px",
      },
    },
    "& h2.h3-fontxl.responsive": {
      [theme.breakpoints.up("lg")]: {
        fontSize: `${utilFunction("h3", "XL", "Desktop", true).sizeVal}vw`,
        lineHeight: utilFunction("h3", "XL", "Desktop", true).sizeVal + 0.11142858 + "vw",
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: `${utilFunction("h3", "XL", "Tablet", true).sizeVal}vw`,
        lineHeight: utilFunction("h3", "XL", "Tablet", true).sizeVal + 0.260714285 + "vw",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: `${utilFunction("h3", "XL", "Mobile", true).sizeVal}vw`,
        lineHeight: utilFunction("h3", "XL", "Mobile", true).sizeVal + 0.472222221 + "vw",
      },
    },
    "& h2.h3-fontl": {
      [theme.breakpoints.up("lg")]: {
        fontSize: utilFunction("h3", "LG", "Desktop").sizeVal,
        lineHeight: utilFunction("h3", "LG", "Desktop").sizeVal + 2 + "px",
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: utilFunction("h3", "LG", "Tablet").sizeVal,
        lineHeight: utilFunction("h3", "LG", "Tablet").sizeVal + 2 + "px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: utilFunction("h3", "LG", "Mobile").sizeVal,
        lineHeight: utilFunction("h3", "LG", "Mobile").sizeVal + 2 + "px",
      },
    },
    "& h2.h3-fontl.responsive": {
      [theme.breakpoints.up("lg")]: {
        fontSize: `${utilFunction("h3", "LG", "Desktop", true).sizeVal}vw`,
        lineHeight: utilFunction("h3", "LG", "Desktop", true).sizeVal + 0.11142858 + "vw",
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: `${utilFunction("h3", "LG", "Tablet", true).sizeVal}vw`,
        lineHeight: utilFunction("h3", "LG", "Tablet", true).sizeVal + 0.260714285 + "vw",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: `${utilFunction("h3", "LG", "Mobile", true).sizeVal}vw`,
        lineHeight: utilFunction("h3", "LG", "Mobile", true).sizeVal + 0.472222221 + "vw",
      },
    },
    "& h2.h3-fontm": {
      [theme.breakpoints.up("lg")]: {
        fontSize: utilFunction("h3", "MD", "Desktop").sizeVal,
        lineHeight: utilFunction("h3", "MD", "Desktop").sizeVal + 2 + "px",
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: utilFunction("h3", "MD", "Tablet").sizeVal,
        lineHeight: utilFunction("h3", "MD", "Tablet").sizeVal + 2 + "px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: utilFunction("h3", "MD", "Mobile").sizeVal,
        lineHeight: utilFunction("h3", "MD", "Mobile").sizeVal + 2 + "px",
      },
    },
    "& h2.h3-fontm.responsive": {
      [theme.breakpoints.up("lg")]: {
        fontSize: `${utilFunction("h3", "MD", "Desktop", true).sizeVal}vw`,
        lineHeight: utilFunction("h3", "MD", "Desktop", true).sizeVal + 0.11142858 + "vw",
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: `${utilFunction("h3", "MD", "Tablet", true).sizeVal}vw`,
        lineHeight: utilFunction("h3", "MD", "Tablet", true).sizeVal + 0.260714285 + "vw",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: `${utilFunction("h3", "MD", "Mobile", true).sizeVal}vw`,
        lineHeight: utilFunction("h3", "MD", "Mobile", true).sizeVal + 0.472222221 + "vw",
      },
    },
    "& h2.h3-fonts": {
      [theme.breakpoints.up("lg")]: {
        fontSize: utilFunction("h3", "SM", "Desktop").sizeVal,
        lineHeight: utilFunction("h3", "SM", "Desktop").sizeVal + 2 + "px",
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: utilFunction("h3", "SM", "Tablet").sizeVal,
        lineHeight: utilFunction("h3", "SM", "Tablet").sizeVal + 2 + "px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: utilFunction("h3", "SM", "Mobile").sizeVal,
        lineHeight: utilFunction("h3", "SM", "Mobile").sizeVal + 2 + "px",
      },
    },
    "& h2.h3-fonts.responsive": {
      [theme.breakpoints.up("lg")]: {
        fontSize: `${utilFunction("h3", "SM", "Desktop", true).sizeVal}vw`,
        lineHeight: utilFunction("h3", "SM", "Desktop", true).sizeVal + 0.11142858 + "vw",
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: `${utilFunction("h3", "SM", "Tablet", true).sizeVal}vw`,
        lineHeight: utilFunction("h3", "SM", "Tablet", true).sizeVal + 0.260714285 + "vw",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: `${utilFunction("h3", "SM", "Mobile", true).sizeVal}vw`,
        lineHeight: utilFunction("h3", "SM", "Mobile", true).sizeVal + 0.472222221 + "vw",
      },
    },
    "& h2.h3-fontxs": {
      [theme.breakpoints.up("lg")]: {
        fontSize: utilFunction("h3", "XS", "Desktop").sizeVal,
        lineHeight: utilFunction("h3", "XS", "Desktop").sizeVal + 2 + "px",
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: utilFunction("h3", "XS", "Tablet").sizeVal,
        lineHeight: utilFunction("h3", "XS", "Tablet").sizeVal + 2 + "px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: utilFunction("h3", "XS", "Mobile").sizeVal,
        lineHeight: utilFunction("h3", "XS", "Mobile").sizeVal + 2 + "px",
      },
    },
    "& h2.h3-fontxs.responsive": {
      [theme.breakpoints.up("lg")]: {
        fontSize: `${utilFunction("h3", "XS", "Desktop", true).sizeVal}vw`,
        lineHeight: utilFunction("h3", "XS", "Desktop", true).sizeVal + 0.11142858 + "vw",
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: `${utilFunction("h3", "XS", "Tablet", true).sizeVal}vw`,
        lineHeight: utilFunction("h3", "XS", "Tablet", true).sizeVal + 0.260714285 + "vw",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: `${utilFunction("h3", "XS", "Mobile", true).sizeVal}vw`,
        lineHeight: utilFunction("h3", "XS", "Mobile", true).sizeVal + 0.472222221 + "vw",
      },
    },
    "& h3.h4-fontxl": {
      [theme.breakpoints.up("lg")]: {
        fontSize: utilFunction("h4", "XL", "Desktop").sizeVal,
        lineHeight: utilFunction("h4", "XL", "Desktop").sizeVal + 2 + "px",
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: utilFunction("h4", "XL", "Tablet").sizeVal,
        lineHeight: utilFunction("h4", "XL", "Tablet").sizeVal + 2 + "px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: utilFunction("h4", "XL", "Mobile").sizeVal,
        lineHeight: utilFunction("h4", "XL", "Mobile").sizeVal + 2 + "px",
      },
    },
    "& h3.h4-fontxl.responsive": {
      [theme.breakpoints.up("lg")]: {
        fontSize: `${utilFunction("h4", "XL", "Desktop", true).sizeVal}vw`,
        lineHeight: utilFunction("h4", "XL", "Desktop", true).sizeVal + 0.11142858 + "vw",
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: `${utilFunction("h4", "XL", "Tablet", true).sizeVal}vw`,
        lineHeight: utilFunction("h4", "XL", "Tablet", true).sizeVal + 0.260714285 + "vw",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: `${utilFunction("h4", "XL", "Mobile", true).sizeVal}vw`,
        lineHeight: utilFunction("h4", "XL", "Mobile", true).sizeVal + 0.472222221 + "vw",
      },
    },
    "& h3.h4-fontl": {
      [theme.breakpoints.up("lg")]: {
        fontSize: utilFunction("h4", "LG", "Desktop").sizeVal,
        lineHeight: utilFunction("h4", "LG", "Desktop").sizeVal + 2 + "px",
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: utilFunction("h4", "LG", "Tablet").sizeVal,
        lineHeight: utilFunction("h4", "LG", "Tablet").sizeVal + 2 + "px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: utilFunction("h4", "LG", "Mobile").sizeVal,
        lineHeight: utilFunction("h4", "LG", "Mobile").sizeVal + 2 + "px",
      },
    },
    "& h3.h4-fontl.responsive": {
      [theme.breakpoints.up("lg")]: {
        fontSize: `${utilFunction("h4", "LG", "Desktop", true).sizeVal}vw`,
        lineHeight: utilFunction("h4", "LG", "Desktop", true).sizeVal + 0.11142858 + "vw",
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: `${utilFunction("h4", "LG", "Tablet", true).sizeVal}vw`,
        lineHeight: utilFunction("h4", "LG", "Tablet", true).sizeVal + 0.260714285 + "vw",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: `${utilFunction("h4", "LG", "Mobile", true).sizeVal}vw`,
        lineHeight: utilFunction("h4", "LG", "Mobile", true).sizeVal + 0.472222221 + "vw",
      },
    },
    "& h3.h4-fontm, p.h4-fontm": {
      [theme.breakpoints.up("lg")]: {
        fontSize: utilFunction("h4", "MD", "Desktop").sizeVal,
        lineHeight: utilFunction("h4", "MD", "Desktop").sizeVal + 2 + "px",
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: utilFunction("h4", "MD", "Tablet").sizeVal,
        lineHeight: utilFunction("h4", "MD", "Tablet").sizeVal + 2 + "px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: utilFunction("h4", "MD", "Mobile").sizeVal,
        lineHeight: utilFunction("h4", "MD", "Mobile").sizeVal + 2 + "px",
      },
    },
    "& h3.h4-fontm.responsive": {
      [theme.breakpoints.up("lg")]: {
        fontSize: `${utilFunction("h4", "MD", "Desktop", true).sizeVal}vw`,
        lineHeight: utilFunction("h4", "MD", "Desktop", true).sizeVal + 0.11142858 + "vw",
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: `${utilFunction("h4", "MD", "Tablet", true).sizeVal}vw`,
        lineHeight: utilFunction("h4", "MD", "Tablet", true).sizeVal + 0.260714285 + "vw",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: `${utilFunction("h4", "MD", "Mobile", true).sizeVal}vw`,
        lineHeight: utilFunction("h4", "MD", "Mobile", true).sizeVal + 0.472222221 + "vw",
      },
    },
    "& h3.h4-fonts": {
      [theme.breakpoints.up("lg")]: {
        fontSize: utilFunction("h4", "SM", "Desktop").sizeVal,
        lineHeight: utilFunction("h4", "SM", "Desktop").sizeVal + 2 + "px",
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: utilFunction("h4", "SM", "Tablet").sizeVal,
        lineHeight: utilFunction("h4", "SM", "Tablet").sizeVal + 2 + "px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: utilFunction("h4", "SM", "Mobile").sizeVal,
        lineHeight: utilFunction("h4", "SM", "Mobile").sizeVal + 2 + "px",
      },
    },
    "& h3.h4-fonts.responsive": {
      [theme.breakpoints.up("lg")]: {
        fontSize: `${utilFunction("h4", "SM", "Desktop", true).sizeVal}vw`,
        lineHeight: utilFunction("h4", "SM", "Desktop", true).sizeVal + 0.11142858 + "vw",
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: `${utilFunction("h4", "SM", "Tablet", true).sizeVal}vw`,
        lineHeight: utilFunction("h4", "SM", "Tablet", true).sizeVal + 0.260714285 + "vw",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: `${utilFunction("h4", "SM", "Mobile", true).sizeVal}vw`,
        lineHeight: utilFunction("h4", "SM", "Mobile", true).sizeVal + 0.472222221 + "vw",
      },
    },
    "& h3.h4-fontxs": {
      [theme.breakpoints.up("lg")]: {
        fontSize: utilFunction("h4", "XS", "Desktop").sizeVal,
        lineHeight: utilFunction("h4", "XS", "Desktop").sizeVal + 2 + "px",
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: utilFunction("h4", "XS", "Tablet").sizeVal,
        lineHeight: utilFunction("h4", "XS", "Tablet").sizeVal + 2 + "px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: utilFunction("h4", "XS", "Mobile").sizeVal,
        lineHeight: utilFunction("h4", "XS", "Mobile").sizeVal + 2 + "px",
      },
    },
    "& h3.h4-fontxs.responsive": {
      [theme.breakpoints.up("lg")]: {
        fontSize: `${utilFunction("h4", "XS", "Desktop", true).sizeVal}vw`,
        lineHeight: utilFunction("h4", "XS", "Desktop", true).sizeVal + 0.11142858 + "vw",
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: `${utilFunction("h4", "XS", "Tablet", true).sizeVal}vw`,
        lineHeight: utilFunction("h4", "XS", "Tablet", true).sizeVal + 0.260714285 + "vw",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: `${utilFunction("h4", "XS", "Mobile", true).sizeVal}vw`,
        lineHeight: utilFunction("h4", "XS", "Mobile", true).sizeVal + 0.472222221 + "vw",
      },
    },
    // button text
    "& button.button-textxl": {
      [theme.breakpoints.up("sm")]: {
        fontSize: utilFunction("button", "XL", "Desktop").sizeVal,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: utilFunction("button", "XL", "Tablet").sizeVal,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: utilFunction("button", "XL", "Mobile").sizeVal,
      },
    },
    "& button.button-textl": {
      [theme.breakpoints.up("sm")]: {
        fontSize: utilFunction("button", "LG", "Desktop").sizeVal,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: utilFunction("button", "LG", "Tablet").sizeVal,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: utilFunction("button", "LG", "Mobile").sizeVal,
      },
    },
    "& button.button-textm": {
      [theme.breakpoints.up("lg")]: {
        fontSize: utilFunction("button", "MD", "Desktop").sizeVal,
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: utilFunction("button", "MD", "Tablet").sizeVal,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: utilFunction("button", "MD", "Mobile").sizeVal,
      },
    },
    "& button.button-texts": {
      [theme.breakpoints.up("sm")]: {
        fontSize: utilFunction("button", "SM", "Desktop").sizeVal,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: utilFunction("button", "SM", "Tablet").sizeVal,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: utilFunction("button", "SM", "Mobile").sizeVal,
      },
    },
    "& button.button-textxs": {
      [theme.breakpoints.up("sm")]: {
        fontSize: utilFunction("button", "XS", "Desktop").sizeVal,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: utilFunction("button", "XS", "Tablet").sizeVal,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: utilFunction("button", "XS", "Mobile").sizeVal,
      },
    },

    //button specific tab component
    "& .MuiTabs-flexContainer button.Mui-selected": {
      fontWeight: 400,
      [theme.breakpoints.up("md")]: {
        fontSize: "24px !important",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "18px !important",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px !important",
      },
    },
    "& .MuiTabs-flexContainer button": {
      fontWeight: 400,
      [theme.breakpoints.up("md")]: {
        fontSize: "24px !important",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "18px !important",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px !important",
      },
    },
    "& .MuiTabs-flexContainer a": {
      [theme.breakpoints.up("md")]: {
        fontSize: "24px !important",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "18px !important",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px !important",
      },
    },
    "& .MuiTabs-root": {
      [theme.breakpoints.up("md")]: {
        minHeight: "60px !important",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "58px !important",
      },
    },

    //button sizes
    "& button.button-size": {},

    // body1 fontsizes
    "& .body1-fontxl p,.body1-fontxl li": {
      [theme.breakpoints.up("lg")]: {
        fontSize: utilFunction("body1", "XL", "Desktop").sizeVal,
        lineHeight: utilFunction("body1", "XL", "Desktop").sizeVal + 2 + "px",
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: utilFunction("body1", "XL", "Tablet").sizeVal,
        lineHeight: utilFunction("body1", "XL", "Tablet").sizeVal + 2 + "px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: utilFunction("body1", "XL", "Mobile").sizeVal,
        lineHeight: utilFunction("body1", "XL", "Mobile").sizeVal + 2 + "px",
      },
    },
    "& .body1-fontxl.responsive p,.body1-fontxl.responsive li": {
      [theme.breakpoints.up("lg")]: {
        fontSize: `${utilFunction("body1", "XL", "Desktop", true).sizeVal}vw`,
        lineHeight: utilFunction("body1", "XL", "Desktop", true).sizeVal + 0.11142858 + "vw",
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: `${utilFunction("body1", "XL", "Tablet", true).sizeVal}vw`,
        lineHeight: utilFunction("body1", "XL", "Tablet", true).sizeVal + 0.260714285 + "vw",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: `${utilFunction("body1", "XL", "Mobile", true).sizeVal}vw`,
        lineHeight: utilFunction("body1", "XL", "Mobile", true).sizeVal + 0.472222221 + "vw",
      },
    },
    "& .body1-fontl p, .body1-fontl li": {
      [theme.breakpoints.up("lg")]: {
        fontSize: utilFunction("body1", "LG", "Desktop").sizeVal,
        lineHeight: utilFunction("body1", "LG", "Desktop").sizeVal + 2 + "px",
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: utilFunction("body1", "LG", "Tablet").sizeVal,
        lineHeight: utilFunction("body1", "LG", "Tablet").sizeVal + 2 + "px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: utilFunction("body1", "LG", "Mobile").sizeVal,
        lineHeight: utilFunction("body1", "LG", "Mobile").sizeVal + 2 + "px",
      },
    },
    "& .body1-fontl.responsive p,.body1-fontl.responsive li": {
      [theme.breakpoints.up("lg")]: {
        fontSize: `${utilFunction("body1", "LG", "Desktop", true).sizeVal}vw`,
        lineHeight: utilFunction("body1", "LG", "Desktop", true).sizeVal + 0.11142858 + "vw",
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: `${utilFunction("body1", "LG", "Tablet", true).sizeVal}vw`,
        lineHeight: utilFunction("body1", "LG", "Tablet", true).sizeVal + 0.260714285 + "vw",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: `${utilFunction("body1", "LG", "Mobile", true).sizeVal}vw`,
        lineHeight: utilFunction("body1", "LG", "Mobile", true).sizeVal + 0.472222221 + "vw",
      },
    },
    "& .body1-fontm p,.body1-fontm li": {
      [theme.breakpoints.up("lg")]: {
        fontSize: utilFunction("body1", "MD", "Desktop").sizeVal,
        lineHeight: utilFunction("body1", "MD", "Desktop").sizeVal + 2 + "px",
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: utilFunction("body1", "MD", "Tablet").sizeVal,
        lineHeight: utilFunction("body1", "MD", "Tablet").sizeVal + 2 + "px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: utilFunction("body1", "MD", "Mobile").sizeVal,
        lineHeight: utilFunction("body1", "MD", "Mobile").sizeVal + 2 + "px",
      },
    },
    "& .body1-fontm.responsive p,.body1-fontm.responsive li": {
      [theme.breakpoints.up("lg")]: {
        fontSize: `${utilFunction("body1", "MD", "Desktop", true).sizeVal}vw`,
        lineHeight: utilFunction("body1", "MD", "Desktop", true).sizeVal + 0.11142858 + "vw",
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: `${utilFunction("body1", "MD", "Tablet", true).sizeVal}vw`,
        lineHeight: utilFunction("body1", "MD", "Tablet", true).sizeVal + 0.260714285 + "vw",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: `${utilFunction("body1", "MD", "Mobile", true).sizeVal}vw`,
        lineHeight: utilFunction("body1", "MD", "Mobile", true).sizeVal + 0.472222221 + "vw",
      },
    },
    "& .body1-fonts p,.body1-fonts li": {
      [theme.breakpoints.up("lg")]: {
        fontSize: utilFunction("body1", "SM", "Desktop").sizeVal,
        lineHeight: utilFunction("body1", "SM", "Desktop").sizeVal + 2 + "px",
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: utilFunction("body1", "SM", "Tablet").sizeVal,
        lineHeight: utilFunction("body1", "SM", "Tablet").sizeVal + 2 + "px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: utilFunction("body1", "SM", "Mobile").sizeVal,
        lineHeight: utilFunction("body1", "SM", "Mobile").sizeVal + 2 + "px",
      },
    },
    "& .body1-fonts.responsive p,.body1-fonts.responsive li": {
      [theme.breakpoints.up("lg")]: {
        fontSize: `${utilFunction("body1", "SM", "Desktop", true).sizeVal}vw`,
        lineHeight: utilFunction("body1", "SM", "Desktop", true).sizeVal + 0.11142858 + "vw",
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: `${utilFunction("body1", "SM", "Tablet", true).sizeVal}vw`,
        lineHeight: utilFunction("body1", "SM", "Tablet", true).sizeVal + 0.260714285 + "vw",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: `${utilFunction("body1", "SM", "Mobile", true).sizeVal}vw`,
        lineHeight: utilFunction("body1", "SM", "Mobile", true).sizeVal + 0.472222221 + "vw",
      },
    },
    "& .body1-fontxs p,.body1-fontxs li": {
      [theme.breakpoints.up("lg")]: {
        fontSize: utilFunction("body1", "XS", "Desktop").sizeVal,
        lineHeight: utilFunction("body1", "XS", "Desktop").sizeVal + 2 + "px",
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: utilFunction("body1", "XS", "Tablet").sizeVal,
        lineHeight: utilFunction("body1", "XS", "Tablet").sizeVal + 2 + "px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: utilFunction("body1", "XS", "Mobile").sizeVal,
        lineHeight: utilFunction("body1", "XS", "Mobile").sizeVal + 2 + "px",
      },
    },
    "& .body1-fontxs.responsive p,.body1-fontxs.responsive li": {
      [theme.breakpoints.up("lg")]: {
        fontSize: `${utilFunction("body1", "XS", "Desktop", true).sizeVal}vw`,
        lineHeight: utilFunction("body1", "XS", "Desktop", true).sizeVal + 0.11142858 + "vw",
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: `${utilFunction("body1", "XS", "Tablet", true).sizeVal}vw`,
        lineHeight: utilFunction("body1", "XS", "Tablet", true).sizeVal + 0.260714285 + "vw",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: `${utilFunction("body1", "XS", "Mobile", true).sizeVal}vw`,
        lineHeight: utilFunction("body1", "XS", "Mobile", true).sizeVal + 0.472222221 + "vw",
      },
    },

    // anchor tags
    "& a": {
      color: "#fff",
      // fontWeight: "bold",
      // fontSize: "20px",
      // textDecorationColor: "black !important",
    },
    "& .elevate-hero-banner a": {
      color: "#fff",
      textDecoration: "underline",
      textDecorationColor: "#fff !important",
    },
    "& a:focus-visible": {
      outline: "1px dotted",
      color: "#fff",
    },

    "& a.header-hover:hover": {
      color: "#404040 !important",
    },

    "& button:focus-visible": {
      outline: "1px dotted #000",
    },

    "& .body1-general a": {
      fontSize: "inherit",
    },

    "& .linkButtonWhite a": {
      color: "white !important",
      fontWeight: "bold",
      fontSize: "20px",
      textDecorationColor: "white !important",
    },

    //svg icons
    "& .button-textm span svg": {
      width: "2.5rem",
      height: "2.5rem",
    },

    // numbered lists

    "& .body1-general ol": {
      margin: 0,
      padding: 0,
      listStyleType: "none",
      marginLeft: "35px",
    },

    "& .body1-general ol li": {
      counterIncrement: "step-counter",
      marginBottom: "30px",
      position: "relative",
    },

    "& .body1-general ol li::before": {
      content: "counter(step-counter)",
      marginRight: "10px",
      backgroundColor: "#000",
      color: "white",
      padding: "4px 10px",
      borderRadius: "50%",
      position: "absolute",
      marginLeft: "-40px",
      marginTop: "-3px",
    },

    "& .body1-general ul": {
      paddingLeft: "20px",
    },

    // svg arrows
    "& .slider-control-centerleft": {
      [theme.breakpoints.up("md")]: {
        left: "8% !important",
        display: "block",
      },
      button: {
        color: "black",
      },
      display: "none",
    },

    "& .slider-control-centerright": {
      [theme.breakpoints.up("md")]: {
        right: "8% !important",
        display: "block",
      },
      button: {
        color: "black",
      },
      display: "none",
    },

    "& .article-body p": {
      fontSize: 18,
      fontWeight: "normal",
      [theme.breakpoints.up("sm")]: {
        fontSize: utilFunction("body1", "MD", "Desktop").sizeVal,
        lineHeight: utilFunction("body1", "MD", "Desktop").sizeVal + 2 + "px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: utilFunction("body1", "MD", "Tablet").sizeVal,
        lineHeight: utilFunction("body1", "MD", "Tablet").sizeVal + 2 + "px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: utilFunction("body1", "MD", "Mobile").sizeVal,
        lineHeight: utilFunction("body1", "MD", "Mobile").sizeVal + 2 + "px",
      },
    },

    "& .article-body .MuiAccordionSummary-content h3": {
      [theme.breakpoints.up("sm")]: {
        fontSize: "28px",
        lineHeight: "30px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "24px",
        lineHeight: "26px",
      },
    },

    "& .article-body .MuiAccordion-root:before": {
      backgroundColor: "transparent",
    },
    // accordion article
    "& .article-body .MuiAccordionSummary-content p.accordion-list": {
      marginRight: "10px",
      backgroundColor: "#000",
      color: "white",
      padding: "5px 10px",
      borderRadius: "50%",
      //position: "absolute",
      marginLeft: "-20px",
    },

    "& .article-body .MuiAccordionSummary-content svg": {
      fontSize: "4.5rem",
    },

    "& .article-body .MuiAccordionSummary-root": {
      paddingTop: 25,
      paddingBottom: 25,
    },

    "& .article-body .MuiAccordionSummary-content": {
      display: "flex",
      alignItems: "flex-start !important",
    },

    "& .article-body .MuiAccordionDetails-root": {
      paddingLeft: 36,
    },

    "& .article-body .MuiAccordion-root:last-of-type": {
      borderBottom: 0,
    },

    "& .article-body .MuiAccordionDetails-root .body1-general p": {
      marginTop: "0",
      marginBottom: "0",
    },

    "& .slider-container .slide .MuiGrid-item": {
      opacity: "0 !important",
    },
    "& .slider-container .slide-visible": {
      opacity: "1 !important",
    },
    "& .slider-container .slide-visible .MuiGrid-item": {
      opacity: "1 !important",
    },
    "& .circle-carousel .slide-current .Overlay": {
      opacity: 0,
    },

    "& .circle-carousel .circle-carousel-content-container ": {
      width: desktop ? "60%" : tablet ? "70%" : "90%",
    },
    "& .circle-carousel .circle-carousel-content-container .circle-carousel-content": {
      opacity: "0 !important",
    },
    "& .circle-carousel .slide-current .circle-carousel-content": {
      opacity: "1 !important",
    },
    "& .circle-carousel .circle-carousel-content-container .circle-carousel-content-image": {
      opacity: "0 !important",
    },
    "& .circle-carousel .slide-current .circle-carousel-content-image ": {
      opacity: "1 !important",
    },
    "& .circle-carousel .slider-container .slider-control-bottomcenter .pagingDotsCustom": {
      [theme.breakpoints.up("lg")]: {
        svg: {
          width: "13px !important",
          height: "13px !important",
        },
      },
      [theme.breakpoints.down("lg")]: {
        svg: {
          width: "13px !important",
          height: "13px !important",
        },
      },
      [theme.breakpoints.down("sm")]: {
        svg: {
          width: "12px !important",
          height: "12px !important",
        },
      },
    },

    //carousel
    "& .slider-container .slider-control-bottomcenter .pagingDots": {
      // top: "0px !important",
      [theme.breakpoints.up("sm")]: {
        svg: {
          width: "13px",
          height: "13px",
          circle: {
            cx: 6,
            cy: 6,
            r: 6,
          },
        },
      },
      [theme.breakpoints.down("sm")]: {
        top: "20px !important",
        svg: {
          width: "13px",
          height: "13px",
          circle: {
            cx: 6,
            cy: 6,
            r: 6,
          },
        },
      },
    },
    "& .slider-container .slider-list": {
      [theme.breakpoints.up("md")]: {
        marginBottom: "20px",
      },
      [theme.breakpoints.up("sm")]: {
        marginBottom: "25px",
      },
      [theme.breakpoints.down("sm")]: {
        marginBottom: "30px",
      },
    },
    "& .stats-li li": {
      listStyle: "none",
    },
    "& .stats-li ul": {
      paddingLeft: 0,
    },
    "& .visibility-hidden": {
      position: "absolute",
      clip: "rect(1px, 1px, 1px, 1px)",
      clipPath: "inset(0px 0px 99.9% 99.9%)",
      overflow: "hidden",
      height: 1,
      width: 1,
      padding: 0,
      border: 0,
    },
    "& a.body1-fonts": {
      [theme.breakpoints.up("lg")]: {
        fontSize: utilFunction("body1", "SM", "Desktop").sizeVal,
        lineHeight: utilFunction("body1", "SM", "Desktop").sizeVal + 2 + "px",
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: utilFunction("body1", "SM", "Tablet").sizeVal,
        lineHeight: utilFunction("body1", "SM", "Tablet").sizeVal + 2 + "px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: utilFunction("body1", "SM", "Mobile").sizeVal,
        lineHeight: utilFunction("body1", "SM", "Mobile").sizeVal + 2 + "px",
      },
    },

    "& a.body1-fontm": {
      [theme.breakpoints.up("lg")]: {
        fontSize: utilFunction("body1", "MD", "Desktop").sizeVal,
        lineHeight: utilFunction("body1", "MD", "Desktop").sizeVal + 2 + "px",
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: utilFunction("body1", "MD", "Tablet").sizeVal,
        lineHeight: utilFunction("body1", "MD", "Tablet").sizeVal + 2 + "px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: utilFunction("body1", "MD", "Mobile").sizeVal,
        lineHeight: utilFunction("body1", "MD", "Mobile").sizeVal + 2 + "px",
      },
    },

    "& a.body1-fontlarge": {
      [theme.breakpoints.up("lg")]: {
        fontSize: utilFunction("body1", "LG", "Desktop").sizeVal,
        lineHeight: "30px",
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: utilFunction("body1", "LG", "Tablet").sizeVal,
        lineHeight: "30px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: utilFunction("body1", "LG", "Mobile").sizeVal,
        lineHeight: "30px",
      },
    },
    "& a.body1-fontsmall": {
      [theme.breakpoints.up("lg")]: {
        fontSize: utilFunction("body1", "SM", "Desktop").sizeVal,
        lineHeight: "30px",
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: utilFunction("body1", "SM", "Tablet").sizeVal,
        lineHeight: "30px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: utilFunction("body1", "SM", "Mobile").sizeVal,
        lineHeight: "30px",
      },
    },

    "& a.body1-fontmedium": {
      [theme.breakpoints.up("lg")]: {
        fontSize: utilFunction("body1", "MD", "Desktop").sizeVal,
        lineHeight: "30px",
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: utilFunction("body1", "MD", "Tablet").sizeVal,
        lineHeight: "30px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: utilFunction("body1", "MD", "Mobile").sizeVal,
        lineHeight: "30px",
      },
    },

    "& a.body1-fontl": {
      [theme.breakpoints.up("lg")]: {
        fontSize: utilFunction("body1", "LG", "Desktop").sizeVal,
        lineHeight: utilFunction("body1", "LG", "Desktop").sizeVal + 2 + "px",
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: utilFunction("body1", "LG", "Tablet").sizeVal,
        lineHeight: utilFunction("body1", "LG", "Tablet").sizeVal + 2 + "px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: utilFunction("body1", "LG", "Mobile").sizeVal,
        lineHeight: utilFunction("body1", "LG", "Mobile").sizeVal + 2 + "px",
      },
    },

    "& a.body1-fonts.header": {
      [theme.breakpoints.between(900, 1024)]: {
        fontSize: "1.3vw",
        lineHeight: utilFunction("body1", "SM", "Tablet").sizeVal + 2 + "px",
      },
      [theme.breakpoints.between(1024, 1200)]: {
        fontSize: "1.65vw",
        lineHeight: utilFunction("body1", "SM", "Tablet").sizeVal + 2 + "px",
      },
    },

    "& a.body1-fonts.footer": {
      [theme.breakpoints.between(900, 1024)]: {
        fontSize: "1vw",
        lineHeight: utilFunction("body1", "SM", "Desktop").sizeVal + 2 + "px",
      },
      [theme.breakpoints.between(1024, 1200)]: {
        fontSize: "1.5vw",
        lineHeight: utilFunction("body1", "SM", "Tablet").sizeVal + 2 + "px",
      },
    },

    "& .herobannerheadline div.MuiBox-root div.MuiBox-root div.MuiBox-root div.MuiBox-root ": {
      [theme.breakpoints.only("xl")]: {
        width: "100%",
      },
      [theme.breakpoints.only("lg")]: {
        width: "100%",
      },
      [theme.breakpoints.only("md")]: {
        width: "100%",
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },

    "& .herobannerheadline h2.h3-fontxl.responsive": {
      [theme.breakpoints.only("xl")]: {
        fontSize: "6vw !important",
        lineHeight: "8vw !important",
        textAlign: "center",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "7vw !important",
        lineHeight: "7.5vw !important",
        textAlign: "center",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "7.292vw !important",
        lineHeight: "8vw !important",
        textAlign: "center",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "13vw !important",
        lineHeight: "14vw !important",
        textAlign: "center",
      },
    },

    "& .herobannerheadline div.body1-general.body1-fontxl.responsive p": {
      [theme.breakpoints.only("xl")]: {
        fontSize: "2.5vw !important",
        lineHeight: "3vw !important",
        textAlign: "center",
        margin: "0% 31%",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "2.5vw !important",
        lineHeight: "3vw !important",
        textAlign: "center",
        margin: "0% 31%",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "2.5vw !important",
        lineHeight: "3vw !important",
        textAlign: "center",
        margin: "0% 31%",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "3vw !important",
        lineHeight: "4vw !important",
        textAlign: "center",
        margin: "0% 20%",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "7vw !important",
        lineHeight: "8vw !important",
        margin: "0% 13%",
      },
    },

    "& .sectionHeading h3.h4-fontxl": {
      [theme.breakpoints.up("lg")]: {
        fontSize: "4.7vw !important",
        lineHeight: "5.5vw !important",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "6vw !important",
        lineHeight: "6.5vw !important",
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: "6vw !important",
        lineHeight: "7vw !important",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "11vw !important",
        lineHeight: "12vw !important",
      },
    },

    "& .solutionsCarousel h2.h3-fontxl": {
      [theme.breakpoints.up("lg")]: {
        fontSize: "4.7vw !important",
        lineHeight: "5.5vw !important",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "6vw !important",
        lineHeight: "6.5vw !important",
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: "6vw !important",
        lineHeight: "7vw !important",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "11vw !important",
        lineHeight: "12vw !important",
      },
    },
    "& .customAlignmentMobile div.MuiGrid-root.MuiGrid-container": {
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        justifyContent: "center",
      },
    },
    "& .customAlignmentMobile h2.h3-fontl": {
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
      },
    },
    "& .customAlignmentMobile h3.h4-fontm": {
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
      },
    },
    "& .titleonlybanner div.MuiBox-root div.MuiBox-root div.MuiBox-root div.MuiBox-root": {
      display: "flex",
      justifyContent: "center",
    },
    "& .circle-carousel-content-container .circle-carousel-content-image h2": {
      [theme.breakpoints.up(600)]: {
        fontSize: "46px !important",
        lineHeight: "22px !important",
        letterSpacing: "-0.5px !important",
      },
      [theme.breakpoints.between(320, 450)]: {
        fontSize: "24px !important",
        lineHeight: "15px !important",
        letterSpacing: "-0.5px !important",
      },
    },
    "& .circle-carousel-content-container .circle-carousel-content-image div p": {
      [theme.breakpoints.up(600)]: {
        fontSize: "20px !important",
        lineHeight: "22px !important",
        letterSpacing: "-0.5px !important",
      },
      [theme.breakpoints.between(320, 450)]: {
        fontSize: "14px !important",
        lineHeight: "15px !important",
        letterSpacing: "-0.5px !important",
      },
    },
    "& .circle-carousel-content-container .circle-carousel-content div p": {
      [theme.breakpoints.up("lg")]: {
        fontSize: "20px",
        lineHeight: "22px",
        letterSpacing: "-0.5px",
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: "16px",
        lineHeight: "18px",
        letterSpacing: "-0.5px",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "20px",
        lineHeight: "22px",
        letterSpacing: "-0.5px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "20px",
        lineHeight: "22px",
        letterSpacing: "-0.5px",
      },
    },
    "& .circle-carousel-content-container .circle-carousel-content div > div a": {
      [theme.breakpoints.down("sm")]: {
        padding: "0 30px",
        display: "flex",
        justifyContent: "center",
        lineHeight: "24px",
      },
    },
    // '& .MuiPaper-root': {
    //   background: 'transparent !important',
    // },
    "& .test.MuiPaper-root": {
      backgroundColor: "transparent",
    },
    "& .MuiDialog-container.MuiPaper-root": {
      background: "#ffffff",
    },
  };

  const httpLink = new HttpLink({ uri: apiConfig.api.apiUrl });

  const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        // authorization: token || null,
      },
    }));
    return forward(operation);
  });

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: concat(authMiddleware, httpLink),
  });
  return (
    <div className="App">
      <StencilTenant tenantId="64997440792351d7dc9e3a4a">
        <ThemeProvider theme={theme}>
          {/* <Provider store={store}> */}
          <GlobalStyles styles={variantStyles} />
          <ApolloProvider client={client}>
            <BrowserRouter>
              <Layout />
            </BrowserRouter>
          </ApolloProvider>
          {/* </Provider> */}
        </ThemeProvider>
      </StencilTenant>
    </div>
  );
}

export default App;
