/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { AppBar, Toolbar } from "@mui/material";

import { useNavigate, useLocation } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";

// import { logo } from '../../Images/Images';
import { meetup_logo } from "../../Images/Images";
import NavMenu from "./NavMenu";

// import Login from '../Login/Login';
// import { storeAuthToken, storeLoggedInUser, storeNotificationStatus } from '../../store/home/actionCreator';
import { AUTH_TOKEN, deleteToken, REFRESH_TOKEN } from "../../Utils/Constants";
import Popup from "./Popup";

import { NavHashLink } from "react-router-hash-link";

const styles = (mobile = false, scrollState = "") => {
  return {
    appBar: {
      backgroundColor: "#000000 !important",
      position: "inherit",
    },
    toolBar: {
      display: "flex",
      justifyContent: "space-between",
      padding: mobile ? "16px 0" : "16px 78px 13px !important",
      backgroundColor: scrollState === "down" ? "#000000" : "transparent",
      transition: "background-color 0.3s",
    },
    brandLogo: {
      marginLeft: mobile ? "20px" : "0",
      marginRight: "10px",
      "&:focus-visible": {
        // background: '#d61b32',
        boxShadow: "none",
        outline: "2px dotted #666666",
        outlineOffset: 2,
      },
    },
    headerLinks: {
      display: "flex",
      textAlign: "center",
    },
    links: {
      width: "130px",
      textDecoration: "none",
      //   padding: '0 20px',
      color: "#FFF",
      // fontWeight: '600',
      "&:focus-visible": {
        // background: '#d61b32',
        boxShadow: "none",
        outline: "2px dotted #666666",
        outlineOffset: 2,
      },
    },
    headerBtns: {
      display: "flex",
      "& div:first-child": {
        // paddingRight: '20px',
      },
    },
    loginBtn: {
      fontSize: 14,
      padding: "12px 35px",
      borderRadius: 50,
      background: "#FFF",
      color: "#333333",
      minWidth: 125,
      // fontFamily: 'Helvetica',
      border: "none",
      cursor: "pointer",
      fontWeight: "bold",
      "&:hover": {
        background: "#d61b32",
        boxShadow: "none",
      },
      "&:focus-visible": {
        background: "#d61b32",
        boxShadow: "none",
        outline: "none",
      },
      "&:disabled": {
        background: "#D6D6D6",
        color: "#333333",
        cursor: "default",
      },
    },
    customButton: {
      fontSize: 14,
      padding: "12px 35px",
      borderRadius: 50,
      background: "#333333",
      color: "#fff",
      minWidth: 125,
      // fontFamily: 'Helvetica',
      border: "none",
      cursor: "pointer",
      fontWeight: "bold",
      "&:hover": {
        background: "#d61b32",
        boxShadow: "none",
      },
      "&:focus-visible": {
        background: "#d61b32",
        boxShadow: "none",
        outline: "none",
      },
      "&:disabled": {
        background: "#D6D6D6",
        color: "#333333",
        cursor: "default",
      },
    },
    userName: {
      color: "#000000",
      fontSize: 19,
      // fontFamily: 'HelveticaforTargetRegular',
      cursor: "pointer",
      textDecoration: "none",
      "&:focus-visible": {
        outline: "2px dotted #666666",
        outlineOffset: 2,
      },
    },
    logout: {
      "&:focus-visible": {
        outline: "2px dotted #666666",
        outlineOffset: 2,
      },
    },
    visuallyHidden: {
      clip: "rect(0 0 0 0)",
      clipPath: "inset(50%)",
      height: "1px",
      overflow: "hidden",
      position: "absolute",
      whiteSpace: "nowrap",
      width: "1px",
    },
    badgeContent: {
      ".MuiBadge-badge": {
        height: 13,
        minWidth: 13,
      },
    },
    notificationButton: {
      border: "none",
      background: "none",
      "&:focus-visible": {
        outline: "2px dotted #666666",
        outlineOffset: 2,
      },
    },
    activeLink: {
      width: "130px",
      textDecoration: "none",
      color: "#FFF",
      // fontFamily: 'HelveticaforTargetBold',
      fontWeight: "bold",
    },
  };
};

const NavBar = ({ loggedInUser, storeLoggedInUser, storeAuthToken, userSuccess, visibleComponent }) => {
  const mobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const navigate = useNavigate();
  let listener = null;
  const [scrollState, setScrollState] = useState("top");

  const location = useLocation();
  const { pathname, hash } = useLocation();
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    listener = document.addEventListener("scroll", (e) => {
      var scrolled = document.scrollingElement.scrollTop;
      const { pathname } = location;
      if (scrolled >= 30 || pathname === "/register" || pathname === "/profile") {
        if (scrollState !== "down") {
          setScrollState("down");
        }
      } else {
        if (scrollState !== "top") {
          setScrollState("top");
        }
      }
    });
    return () => {
      document.removeEventListener("scroll", listener);
    };
  }, [scrollState]);

  // const classes = useStyles({ mobile, scrollState });

  const logOut = () => {
    storeLoggedInUser({});
    storeAuthToken("");
    deleteToken(AUTH_TOKEN);
    deleteToken(REFRESH_TOKEN);
    setOpen(true);
  };

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    navigate("/");
  };

  // useEffect(async () => {
  //   // await getNotificationData();
  // }, []);

  useEffect(() => {
    if (pathname !== "" && !hash) {
      console.log("pathname ", pathname, hash);
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }, 200);
    }
    if (pathname === "/") {
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }, 200);
    }
    if (hash) {
      setTimeout(() => {
        let el = document.getElementById(hash.replace("#", ""));
        if (el) {
          const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
          const yOffset = -90;
          window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
        }
      }, 1800);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -100;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };
  console.log("location.hash ", location.hash === "", visibleComponent);
  return (
    <>
      {mobile ? (
        // for mobile view
        <AppBar elevation={0} sx={styles().appBar}>
          <Toolbar sx={styles(mobile, scrollState).toolBar} style={{ height: 80 }}>
            <NavHashLink to="/" style={styles(mobile).brandLogo} scroll={(el) => scrollWithOffset(el)}>
              <img
                src={meetup_logo}
                alt="Target Logos"
                height="54"
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/")}
              />
            </NavHashLink>
            <NavMenu loggedInUser={loggedInUser} logOut={logOut} userSuccess={userSuccess} />
          </Toolbar>
        </AppBar>
      ) : (
        //for desktop view
        <AppBar elevation={0} sx={styles().appBar}>
          <Toolbar sx={styles().toolBar} style={{ height: 80 }}>
            <div style={{ paddingLeft: "15px" }}>
              {/* below h1 is for keyboard users */}
              <h1 style={styles().visuallyHidden}>Target MeetUp </h1>
              <NavHashLink to="/" style={styles().brandLogo} scroll={(el) => scrollWithOffset(el)}>
                <img src={meetup_logo} alt="meetup" style={{ cursor: "pointer" }} onClick={() => navigate("/")} />
              </NavHashLink>
            </div>
            <Popup open={open} handleClose={handleClose} message={"Logout Successful!"} />

            <div style={styles().headerLinks}>
              <NavHashLink
                to="/#"
                style={
                  location.hash === "#home" || (location.pathname === "/" && location.hash === "")
                    ? styles().activeLink
                    : styles().links
                }
                scroll={(el) => scrollWithOffset(el)}
              >
                Home
              </NavHashLink>
              {/* <NavHashLink
                to='/#speakers'
                // style={{ width: '180px' }}
                style={location.hash === '#speakers' ? styles().activeLink : styles().links}
                scroll={(el) => scrollWithOffset(el)}
              >
                Speakers
              </NavHashLink> */}
              {/* <NavHashLink
                to='/#whymultiplai'
                style={{ width: '180px' }}
                className={location.hash === '#whymultiplai' ? styles().activeLink : styles().links}
                scroll={(el) => scrollWithOffset(el)}
              >
                Whats's in store
              </NavHashLink> */}
              {/* <NavHashLink
                to='/#agenda'
                // style={{ width: '180px' }}
                style={location.hash === '#agenda' ? styles().activeLink : styles().links}
                scroll={(el) => scrollWithOffset(el)}
              >
                Agenda
              </NavHashLink> */}

              {/* <NavHashLink to='/gallery' style={location.pathname === '/gallery' ? styles().activeLink : styles().links} scroll={(el) => scrollWithOffset(el)}>
                Gallery
              </NavHashLink> */}
              <NavHashLink
                to="/faq"
                style={location.pathname === "/faq" ? styles().activeLink : styles().links}
                scroll={(el) => scrollWithOffset(el)}
              >
                FAQs
              </NavHashLink>
            </div>
            {/* <div className={styles().headerBtns}>
              <Login />
              <SignUp isheader={true} />
            </div> */}
          </Toolbar>
        </AppBar>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  // const homeData = state.home;

  return {
    //   loggedInUser: homeData.loggedInUser,
    //   userSuccess: homeData.userSuccess,
    //   visibleComponent: homeData.inViewStatus,
  };
};

const mapDispatchToProps = {
  // storeLoggedInUser,
  // storeAuthToken,
  // storeNotificationStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
