import AgendaBuilder from './AgendaBuilder';
import RetailAgendaData from './RetailAgenda.json';
const RetailAgenda = () => {
  return (
    <>
      <div>
        <AgendaBuilder agendaData={RetailAgendaData} />
      </div>
    </>
  );
};

export default RetailAgenda;
