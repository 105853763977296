/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
// import axios from 'axios';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { Grid, TextField } from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";
import { IsEmail } from "../../Utils/Utils";
import { Helmet } from "react-helmet";
import { whiteCrossSvg } from "../../assets/images/Images";
import { setEmailId, setExistingUserData, useValidateUserMutation } from "../../api/validateUser";
import { makeStyles } from "@mui/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  customButton: {
    // backgroundImage: '-webkit-linear-gradient(left,rgb(204,0,0), rgb(189,0,119))',
    backgroundColor: "#CC0000",
    fontSize: 14,
    padding: "12px 26px",
    borderRadius: 50,
    background: "#CC0000",
    color: "#fff",
    fontWeight: 600,
    border: "2px solid #CC0000",
    cursor: "pointer",
    "&:hover": {
      background: "#d61b32",
      boxShadow: "none",
    },
    "&:focus": {
      background: "#d61b32",
      outline: "2px dotted #666666",
      outlineOffset: 2,
    },
    "&:disabled": {
      background: "#D6D6D6",
      color: "#333333",
      cursor: "default",
    },
  },
  cancelButton: {
    fontSize: 14,
    padding: "12px 26px",
    borderRadius: 50,
    background: "#CC0000",
    color: "#fff",
    border: "none",
    fontWeight: 600,
    cursor: "pointer",
    "&:hover": {
      boxShadow: "none",
      color: "#FFF",
    },
    "&:focus": {
      background: "#333333",
      boxShadow: "none",
      color: "#FFF",
      outline: "2px dotted #666666",
      outlineOffset: 2,
    },
  },
  root: {
    "& .MuiOutlinedInput-root": {
      height: 50,
      "& fieldset": {
        border: "2px solid #FFF",
        fontSize: 14,
        borderRadius: `50px`,
      },
      "&.Mui-focused": {
        borderRadius: `50px`,
        outline: "2px dotted #666666",
        outlineOffset: 2,
      },
    },
    "& .MuiOutlinedInput-input": {
      textIndent: 9,
      color: "#FFFFFF",
    },
    "& .MuiInputBase-input": {
      color: "#FFFFFF",
    },
  },
  errorLabel: {
    textAlign: "center",
    color: "#EB0000",
    fontWeight: "bold",
    fontSize: 14,
    marginTop: 16,
  },
  signUpText: {
    marginTop: 0,
    color: "#EB0000",
    fontSize: "34px !important",
    fontWeight: 600,
    marginBottom: 20,
    textAlign: "left",
  },
  underLine: {
    border: "2px solid #cc0000",
    backgroundColor: "#cc0000",
    width: 80,
    marginBottom: 31,
  },
  messageLabel: {
    paddingTop: 10,
    color: "#EB0000",
    fontSize: 16,
    textAlign: "left",
  },
  labelStyles: {
    fontSize: 22,
    fontWeight: 400,
    marginBottom: 4,
    color: "#FFFFFF",
  },
  closeButton: {
    border: "none",
    width: "20px",
    height: "20px",
    padding: 0,
    background: `url(${whiteCrossSvg}) no-repeat`,
    "&:focus-visible": {
      outline: "2px dotted #666666",
      outlineOffset: 2,
    },
    "&:hover": {
      opacity: 0.75,
    },
  },
  dialog: {
    // borderRadius: '25px',
    backgroundColor: "rgb(247 244 244 / 78%) !important",
    "& .MuiDialog-paper": {
      maxWidth: 479,
      // background: '#000 !important',
      borderRadius: "25px !important",
      width: "100% !important",

      backgroundPosition: "bottom !important",
      backgroundRepeat: "no-repeat !important",
      backgroundSize: "100% 100% !important",
    },
  },
  crossMark: {
    height: 17,
    width: 17,
    backgroundColor: "#ffffff",
    "&:hover": {
      opacity: 0.75,
      backgroundColor: "#fff",
    },
  },
}));

function SignUp({
  storeEmailId,
  storeUserDetails,
  userSuccess = false,
  existingUser = false,
  isheader = false,
  openSignUp,
  setopenSignUp = () => {},
  isRedirect = false,
  redirectToSignup,
  isSpotReg,
  isBanner,
  isDirectHit = false,
  isWs = false,
  handleDismiss = () => {},
}) {
  // 2024 start
  const classes = useStyles();
  const [validateUser, { data: validateUserResponse }] = useValidateUserMutation();
  const eventId = useSelector((state) => state.tenant.eventId);
  const dispatch = useDispatch();
  // end
  const [open, setOpen] = useState(false);
  const [emailId, setEmail] = useState("");
  const [isError, setError] = useState(false);
  const [message, setMessage] = useState("");
  // const [checkExistingUser] = useMutation(CHECK_EXISTING_USER);
  const emailRef = useRef();

  const tab = useMediaQuery("(max-width:550px)");

  const mobile = useMediaQuery("(max-width:416px)");
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const navigate = useNavigate();

  const handleClickOpen = () => {
    //to open the pop up
    setOpen(true);
  };

  const handleClose = () => {
    handleDismiss();
    if (isMobile || isDirectHit) {
      setopenSignUp(false);
    }
    setOpen(false);
    setMessage("");
    setEmail("");
    setError("");
  };

  const handleChange = (e) => {
    setEmail(e.target.value);
    setError(false);
    setMessage("");
  };
  useEffect(() => {
    if (existingUser?.memeber) {
      setMessage(
        <div
          role="alert"
          className={classes.messageLabel}
          style={{
            width: mobile ? 223 : tab ? 300 : 375,
            color: "#cc0000",
            fontWeight: "bold",
          }}
        >
          {`Thanks for updating your information.
                    We've already saved a seat for you. Check your Inbox for details soon and head to our `}
          <NavLink
            to="/gallery"
            style={{
              fontWeight: "bold",
              color: "#0d6efd",
              cursor: "pointer",
              textDecoration: "none",
            }}
          >
            Gallery
          </NavLink>
          {` page for glimpses of past editions.`}
        </div>
      );
    } else if (userSuccess) {
      setMessage(
        <div
          role="alert"
          className={classes.messageLabel}
          style={{
            width: mobile ? 223 : tab ? 300 : 375,
            color: "#FFFFFF",
            fontWeight: "bold",
          }}
        >
          {`You have been registered for the Target Design Meetup 2024. Look for details in your Inbox from meetup@target.com`}
          {/* <NavLink
                        to='/gallery'
                        style={{ fontWeight: 'bold', color: '#333333', cursor: 'pointer', fontFamily: 'HelveticaforTargetBold', textDecoration: 'none' }}
                    >
                        Gallery
                    </NavLink>
                    {` page for glimpses of past editions.`} */}
        </div>
      );
    }
    window.scrollTo(0, 0);
  }, [userSuccess, existingUser]);

  useEffect(() => {
    if (isRedirect || isDirectHit) {
      if (isMobile || isDirectHit) {
        setopenSignUp(true);
      }
      setOpen(true);
      // redirectToSignup(false);
    }
  }, [isRedirect]);

  useEffect(() => {
    if (validateUserResponse) {
      //setValidateUserResData(validateUserResponse);

      if (validateUserResponse.status[0].status === "REGISTERED" && validateUserResponse.status[0].year === 2024) {
        setMessage(
          <div
            role="alert"
            className={classes.messageLabel}
            style={{ width: mobile ? 223 : tab ? 300 : "100%", color: "#cc0000" }}
          >
            {`Hey, looks like you've already applied. Keep an eye on your Inbox for updates.`}
          </div>
        );
        return;
      }
      if (validateUserResponse.status[0].status === "APPROVED" && validateUserResponse.status[0].year === 2024) {
        setMessage(
          <div
            role="alert"
            className={classes.messageLabel}
            style={{ width: mobile ? 223 : tab ? 300 : "100%", color: "#cc0000" }}
          >
            {`Looks like you've already registered and we've saved a seat for you! Keep an eye on your Inbox for details.`}
          </div>
        );
        return;
      }
      // change the copy of the below scenarios
      if (validateUserResponse.status[0].status === "REJECTED" && validateUserResponse.status[0].year === 2024) {
        // should i need to consider the year for rejected.
        setMessage(
          <div
            role="alert"
            className={classes.messageLabel}
            style={{ width: mobile ? 223 : tab ? 300 : "100%", color: "#cc0000" }}
          >
            {`Hey, looks like you've already applied. Keep an eye on your Inbox for updates.`}
          </div>
        );
        return;
      }
      if (validateUserResponse.status[0].status === "ON_HOLD" && validateUserResponse.status[0].year === 2024) {
        setMessage(
          <div
            role="alert"
            className={classes.messageLabel}
            style={{ width: mobile ? 223 : tab ? 300 : "100%", color: "#cc0000" }}
          >
            {`Hey, looks like you've already applied. Keep an eye on your Inbox for updates.`}
          </div>
        );
        return;
      }

      if (validateUserResponse.status[0].status === "BLOCKED" && validateUserResponse.status[0].year === 2024) {
        setMessage(
          <div
            role="alert"
            className={classes.messageLabel}
            style={{ width: mobile ? 223 : tab ? 300 : "100%", color: "#cc0000" }}
          >
            {`Uh-oh! Looks like you've unsubscribed from our mailing list. To access the event, please write to meetup@target.com.`}
          </div>
        );
        return;
      }

      // if we have the user data, no matter whats their status except rejected, we take them to form with pre populated data.
      if (validateUserResponse.member) {
        dispatch(
          setExistingUserData({
            member: validateUserResponse.member,
            meta: validateUserResponse.meta,
            status: validateUserResponse.status,
          })
        );

        setOpen(false);
        setopenSignUp(false);
        navigate("/register");
        if (isMobile) {
          setopenSignUp(false);
        }
      } else {
        // if we dont have the user data, we take them to new form.
        setOpen(true);
        setopenSignUp(false);
        // navigate('/registeronspot');
      }
    }
  }, [validateUserResponse]);

  const handleContinue = async (e) => {
    e.preventDefault();
    const lowerCaseMail = (emailId || "").toLowerCase();
    if (!IsEmail(lowerCaseMail)) {
      setError(true);
      emailRef.current.focus();
    } else {
      setError(false);
      setEmail(lowerCaseMail);
      try {
        // store the email id in Redux store
        dispatch(
          setEmailId({
            email_id: lowerCaseMail,
          })
        );
        // making an API call to know the details about the user by sending user entered email id.
        validateUser({
          eventId,
          email_id: {
            email: lowerCaseMail,
          },
        });
      } catch (error) {
        console.log("In catch block ", error);
      }
    }
  };
  return (
    <Grid>
      <Helmet>
        {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
        {/* <script async src='https://www.googletagmanager.com/gtag/js?id=G-SRT1SJE7W0'></script>
        <script>
          {`  window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());

                        gtag('config', 'G-SRT1SJE7W0');`}
        </script> */}
      </Helmet>
      {userSuccess ? (
        isheader ? null : (
          <div style={{ display: "flex", justifyContent: "left" }}>{message} </div>
        )
      ) : isMobile ? (
        isWs ? (
          <button
            className={classes.customButton}
            style={{ marginTop: "25px" }}
            type="button"
            onClick={handleClickOpen}
          >
            {"Register here"}
          </button>
        ) : null
      ) : (
        <></>
      )}
      {/* {isSpotReg ? (
        <button className={classes.customButton} type='button' onClick={handleClickOpen}>
          {'Request an invitation'}
        </button>
      ) : null} */}

      <Dialog
        // open={isMobile ? openSignUp : open}
        open={!isMobile || (isMobile && isWs) ? open : openSignUp}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        className={classes.dialog}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          sx: {
            // borderRadius: '25px',
            // width: '100%',
            // backgroundImage: mobile ? `url(${mobilePopUpBg})` : `url(${popUpBg})`,
            backgroundColor: "#000000",
            // backgroundPosition: 'bottom',
            // backgroundRepeat: 'no-repeat',
            // backgroundSize: '100% 22%',
            // border: '1px solid #eb0000',
          },
        }}
      >
        <div style={{ textAlign: "right", padding: "10% 10% 0 0" }}>
          <button aria-label="close" onClick={() => handleClose()} className={classes.closeButton}></button>
        </div>
        <DialogContent
          style={{
            padding: tab || mobile ? "0px 24px 70px" : "20px 30px 70px",
          }}
        >
          <form onSubmit={(e) => handleContinue(e)} id="Sigup Form">
            <h2 className={classes.signUpText} style={{ fontSize: tab ? 35 : 44 }}>
              Register to Join
            </h2>
            {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div className={classes.underLine} />
                        </div> */}
            <div>
              <div className={classes.labelStyles} style={{ textAlign: "left" }} aria-label="required">
                {"Email Address"}
                <span aria-hidden="true" style={{ color: "#FFF" }}>
                  *
                </span>
              </div>
              <TextField
                type={"text"}
                name={"emailId"}
                placeholder={"Enter your email here"}
                onChange={(e) => handleChange(e)}
                value={emailId}
                style={{ width: "100%", padding: "20px 0" }}
                classes={{ root: classes.root }}
                inputRef={emailRef}
              />
              {!isError ? null : <div className={classes.errorLabel}>{"Please enter a valid email address"}</div>}
              {!message ? null : message}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 16,
              }}
            >
              {/* <button className={classes.cancelButton} type='button' onClick={handleClose} style={{ padding: tab || mobile ? 14 : '12px 26px' }}>
                Cancel
              </button> */}
              <button
                className={classes.customButton}
                type="submit"
                disabled={message ? true : false}
                style={{ padding: tab || mobile ? 14 : "12px 26px" }}
              >
                continue
              </button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </Grid>
  );
}

export default SignUp;
