/* eslint-disable react-hooks/exhaustive-deps */
// import { useLazyQuery } from '@apollo/client';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
// import { makeStyles } from '@mui/styles';
import moment from "moment";
// import { useEffect } from 'react';
// import { GET_AGENDA } from '../Utils/Queries/Queries';
// import agendaData from './AgendaData.json';
// import DottedWebBack from '../Images/DottedWebBack.png';
const styles = (theme, mobile, desktop) => {
  return {
    agendaContainer: {
      width: "100% !important",
      margin: "0 auto",
      "& td": {
        borderBottom: "0.5px solid #000000",
      },
    },
    containerHeading: {
      color: "#FFFFFF",
      textAlign: "center",
      fontSize: "55px !important",
      fontWeight: "bold !important",
      [theme.breakpoints.down("md")]: {
        fontSize: "26px !important",
      },
    },
    timings: {
      width: "210px",
      color: "#FFF !important",
      fontWeight: "bold !important",
      minWidth: "150px",
      textAlign: "left !important",
      fontSize: "18px !important",
    },
    timingsForMobile: {
      width: "200px",
      color: "#FFF",
      fontWeight: "bold !important",
      minWidth: "150px",
      textAlign: "left !important",
      fontSize: "18px !important",
      border: "1px solid #CC0000",
      // backgroundImage: '-webkit-linear-gradient(left,rgb(204,0,0), rgb(189,0,119))',
      backgroundColor: "#CC0000",
      padding: "5px",
    },
    sessionTitle: {
      width: "100px",
      fontSize: "18px !important",
    },
    speakerSessionTitle: {
      // maxWidth: desktop ? '600px' : '300px',
      minWidth: desktop ? "500px" : "350px",
      borderBottom: "none !important",
      padding: "0px !important",
      color: "#FFF !important",
      // fontFamily: 'HelveticaforTarget-Light !important',
      // fontSize: (props) => (props.desktop ? '30px !important' : '22px !important'),
      fontSize: "22px !important",
    },
    speakerSessionTitleMobile: {
      // minWidth: '400px',
      borderBottom: "none !important",
      paddingLeft: "0 !important",
      color: "#FFF !important",
      fontWeight: "bold !important",
      fontSize: "18px !important",
      paddingBottom: "5px",
    },
    speakerSessionDesc: {
      borderBottom: "none",
      paddingLeft: "0",
      color: "#FFF",
      // fontFamily: 'HelveticaforTarget-Light !important',
      fontSize: "18px",
      paddingBottom: "5px",
    },
    sessionSpeakerContainer: {
      borderBottom: "none !important",
      minWidth: "400px",
      // fontWeight:'bold !important',
      color: "#FFF !important",
    },
    sessionSpeakerContainerMobile: {
      borderBottom: "none !important",
      color: "#FFF !important",
      paddingBottom: "4px",
    },
    sessionSpeakerName: {
      fontWeight: "bold",
      color: "#FFF",
      fontSize: "18px",
    },
    sessionSpeakerOrg: {
      // fontFamily: 'HelveticaforTarget-Light !important',
      color: "#FFF",
      fontWeight: "300",
    },
  };
};
function getSessionTime(startTime, endTime) {
  const start = moment(startTime)
    .format("hh:mm a")
    .replace(/\b([ap])m\b/gi, "$1.m.");

  if (endTime === "onwards") {
    console.log(":IN IF ");
    return `${start} ${endTime}`;
  }

  const end = moment(endTime)
    .format("hh:mm a")
    .replace(/\b([ap])m\b/gi, "$1.m.");

  return `${start} - ${end}`;
}
function getSpeakerInfo(speakerData, theme, isMobile, desktop) {
  const multiSpeaker = speakerData.fullName.split("|");
  const multiSpeakerDesignation = speakerData.designation.split("|");
  if (speakerData.fullName.trim() === "Workshop" || speakerData.fullName === " ") {
    return null;
  } else {
    if (multiSpeaker.length > 1) {
      return multiSpeaker.map((speaker, index) => (
        <div
          style={{
            display: isMobile ? "flex" : "",
            flexDirection: isMobile ? "column" : "",
            lineHeight: "1.6",
          }}
          key={index}
        >
          <span style={styles(theme, isMobile, desktop).sessionSpeakerName}> {isMobile ? speaker : `${speaker}`} </span>
          {!isMobile && <br />}

          <span style={styles(theme, isMobile, desktop).sessionSpeakerOrg}>{multiSpeakerDesignation[index]}</span>
          {!isMobile && <br />}
          {!isMobile && <br />}
        </div>
      ));
    } else {
      return (
        <>
          {speakerData.fullName !== " " || speakerData.organisation !== "" ? (
            <>
              <span style={styles(theme, isMobile, desktop).sessionSpeakerName}>
                {isMobile ? speakerData.fullName : `${speakerData.fullName}`}
              </span>
              {!isMobile && <br />}
              <span style={styles(theme, isMobile, desktop).sessionSpeakerOrg}>
                {speakerData.organisation
                  ? speakerData.designation + ", " + speakerData.organisation
                  : speakerData.designation}
              </span>
            </>
          ) : null}
        </>
      );
    }
  }
}
const RenderSessionInfo = ({ sessionSpeakers, session }) => {
  const mobile = useMediaQuery((theme) => theme.breakpoints.up("xs") && theme.breakpoints.down("sm"));
  const desktop = useMediaQuery((theme) => theme.breakpoints.between(1701, 2560));
  // const classes = useStyles({ desktop });
  const theme = useTheme();
  return (
    <>
      {sessionSpeakers &&
        sessionSpeakers.map((sessionSpeaker, index) => {
          return (
            <>
              <TableRow key={index}>
                {getSpeakerInfo(sessionSpeaker, theme, false, desktop) !== null ? (
                  <TableCell
                    item
                    md={12}
                    sx={{
                      ...styles(theme, mobile, desktop).speakerSessionTitle,
                      verticalAlign: "baseline",
                      lineHeight: "1",
                    }}
                  >
                    <span style={styles(theme, mobile, desktop).sessionSpeakerName}>
                      {getSpeakerInfo(sessionSpeaker, theme, false, desktop)}
                    </span>
                  </TableCell>
                ) : null}

                <TableCell
                  item
                  md={4}
                  sm={12}
                  sx={{
                    ...styles(theme, mobile, desktop).speakerSessionTitle,
                    verticalAlign: "baseline",
                    lineHeight: "1.5",
                    color: sessionSpeaker.title === "Workshops" ? "#EB0000 !important" : "#FFF",
                    fontWeight: sessionSpeaker.title === "Workshops" ? "bold !important" : "400",
                    fontSize: desktop ? "18px !important" : "20px !important",
                  }}
                >
                  <span style={{ fontWeight: "bold", paddingBottom: "10px" }}>{sessionSpeaker.title}</span> <br />
                  {sessionSpeaker?.subtitle}
                </TableCell>
                {sessionSpeaker?.about_user !== "" && (
                  <TableCell
                    item
                    md={12}
                    sx={{
                      ...styles(theme, mobile, desktop).speakerSessionTitle,
                      verticalAlign: "baseline",
                      minWidth: desktop ? "500px" : "450px",
                      lineHeight: "1",
                    }}
                  >
                    <span style={styles(theme, mobile, desktop).speakerSessionDesc}>
                      {sessionSpeaker.about_user?.split("|").length > 1
                        ? sessionSpeaker.about_user?.split("|")[1]
                        : sessionSpeaker.about_user?.split("|")[0]}
                    </span>
                  </TableCell>
                )}
              </TableRow>
            </>
          );
        })}
    </>
  );
};
const RenderSessionInfoForMobile = ({ sessionSpeakers }) => {
  // const classes = useStyles();
  const mobile = useMediaQuery((theme) => theme.breakpoints.up("xs") && theme.breakpoints.down("sm"));
  const desktop = useMediaQuery((theme) => theme.breakpoints.between(1701, 2560));
  const theme = useTheme();
  return (
    <>
      {sessionSpeakers &&
        sessionSpeakers.map((sessionSpeaker, index) => (
          <>
            <div style={{ paddingBottom: "15px" }} key={index}>
              <Grid item md={4} sm={12} sx={styles(theme, mobile, desktop).speakerSessionTitleMobile}>
                {"- " + sessionSpeaker.title}
                <br />
                {sessionSpeaker?.subtitle}
              </Grid>
              <Grid item md={12} sx={styles(theme, mobile, desktop).sessionSpeakerContainerMobile}>
                <span
                  style={{
                    ...styles(theme, mobile, desktop).sessionSpeakerName,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <br /> {getSpeakerInfo(sessionSpeaker, theme, false, desktop)}
                </span>
              </Grid>
            </div>
          </>
        ))}
    </>
  );
};
// delete this comment
const AgendaBuilder = ({ agendaData }) => {
  const mobile = useMediaQuery((theme) => theme.breakpoints.up("xs") && theme.breakpoints.down("sm"));
  const tab = useMediaQuery((theme) => theme.breakpoints.up("sm") && theme.breakpoints.down("md"));
  const desktop = useMediaQuery((theme) => theme.breakpoints.between(1701, 2560));
  const theme = useTheme();
  const agendas = agendaData && agendaData["rawEvents"];

  return (
    <>
      <Grid
        container
        id="agenda"
        alignItems="center"
        justifyContent="center"
        style={{
          backgroundColor: "#000",
          padding: mobile ? "2% 8% 40px 5%" : "6% 8% 40px",
          // marginTop: mobile ? '20%' : '5%',
        }}
      >
        <Grid item lg={12} md={12} sm={12}>
          <Typography
            variant="h2"
            sx={{ ...styles(theme, mobile, desktop).containerHeading, paddingTop: mobile ? "25px" : "0" }}
          >
            Agenda
          </Typography>
          {/* <Typography
            style={{
              paddingTop: '20px',
              textAlign: 'left',
              color: '#FFF',
              fontSize: '16px',
            }}
          >
            Click{'  '}
            {
              <a
                style={{ color: '#FFF', fontFamily: 'HelveticaforTargetBold' }}
                target='_blank'
                href='https://gfc.target.com/multiplai/2023/MultiplaiAgendaAccessible.pdf'
                rel='noreferrer'
                title='opens in a new window'
              >
                here
              </a>
            }
            {'  '} to download the full agenda.
          </Typography> */}
        </Grid>
      </Grid>
      <>
        {mobile || tab ? (
          // <AgendaForMobile agendas={agendas} getSessionTime={getSessionTime} />
          <Grid
            container
            style={{
              backgroundColor: "#000",
              padding: mobile ? "0px 20px 5px 20px" : tab ? "0px 0px 0px 20px" : "",
              textAlign: "left",
            }}
          >
            {agendas &&
              agendas[0].session.map((session, index) => (
                <div style={{ borderTop: "0.5px solid #CC0000", width: "100%" }} key={index}>
                  <Grid item md={12} sm={12} sx={{ padding: "0 0 15px 0", marginTop: "3px" }}>
                    <span style={styles(theme, mobile, desktop).timingsForMobile}>
                      {getSessionTime(session.start_time, session.end_time)}
                    </span>
                  </Grid>

                  <Grid item md={12} sm={12} sx={{ paddingBottom: "15px" }}>
                    {session.description ? (
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{
                            color: "#FFF",
                            fontWeight: "bold !important",
                            fontSize: "18px",
                          }}
                        >
                          {"- " + session.title}
                        </span>
                        <span
                          style={{
                            color: "#FFF",
                            fontWeight: "bold !important",
                            fontSize: "18px",
                          }}
                        >
                          {session.description}
                        </span>
                      </div>
                    ) : session.speakers && session.speakers.length > 0 ? (
                      <RenderSessionInfoForMobile sessionSpeakers={session.speakers} />
                    ) : (
                      <span
                        style={{
                          color: "#FFF",
                          fontWeight: "bold !important",
                          fontSize: "18px",
                        }}
                      >
                        {"- " + session.title}
                      </span>
                    )}
                  </Grid>
                </div>
              ))}
          </Grid>
        ) : (
          <div
            style={{
              paddingBottom: "50px",
              backgroundColor: "#000",
              // backgroundImage: `url(${DottedWebBack})`,
              // backgroundRepeat: 'no-repeat',
              // backgroundPosition: 'bottom',
              // backgroundSize: '100%',
              marginBottom: "-8px",
            }}
          >
            <Grid container sx={styles(theme, mobile, desktop).agendaContainer}>
              <TableContainer component={Paper} sx={{ backgroundColor: "transparent", boxShadow: "none" }}>
                <Table aria-label="agenda" sx={{ width: "80%", marginLeft: "8%" }}>
                  <TableBody>
                    {agendas &&
                      agendas[0].session.map((session) => (
                        <>
                          <TableRow
                            key={session.name}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                              borderTop: "0.5px solid #CC0000",
                              display: "flex",
                            }}
                          >
                            <TableCell
                              align="right"
                              sx={{
                                ...styles(theme, mobile, desktop).timings,
                                verticalAlign: "baseline",
                                padding: "8px 0 0 0",
                              }}
                            >
                              <span style={{ padding: "10px", backgroundColor: "#CC0000" }}>
                                {" "}
                                {getSessionTime(session.start_time, session.end_time)}
                              </span>
                            </TableCell>
                          </TableRow>
                          <TableRow
                            key={session.name}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            {/* <TableCell align='right' className={classes.timings} style={{ verticalAlign: 'baseline' }}>
                              {getSessionTime(session.start_time, session.end_time)}
                            </TableCell> */}
                            {session.description && session.speakers.length <= 0 ? (
                              <TableCell sx={{ verticalAlign: "baseline" }}>
                                <TableRow>
                                  <TableCell
                                    sx={{
                                      verticalAlign: "baseline",
                                      ...styles(theme, mobile, desktop).speakerSessionTitle,
                                    }}
                                  >
                                    <span
                                      style={{
                                        color: "#FFF",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {session.title}
                                    </span>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      verticalAlign: "baseline",
                                      paddingLeft: 0,
                                      ...styles(theme, mobile, desktop).sessionSpeakerContainer,
                                    }}
                                  >
                                    <span
                                      style={{
                                        color: "#FFF",
                                        fontWeight: "bold",
                                        fontSize: "18px",
                                      }}
                                    >
                                      {session.description}
                                    </span>
                                  </TableCell>
                                </TableRow>
                              </TableCell>
                            ) : (
                              <TableCell
                                scope="session"
                                sx={{
                                  verticalAlign: "baseline",
                                  // padding: 0,
                                  ...styles(theme, mobile, desktop).sessionTitle,
                                }}
                              >
                                {session.speakers && session.speakers.length > 0 ? (
                                  <RenderSessionInfo sessionSpeakers={session.speakers} session={session} />
                                ) : (
                                  <span
                                    style={{
                                      color: "#FFF",
                                      fontWeight: "bold",
                                      // paddingTop: "18px",
                                      // display: "block",
                                    }}
                                  >
                                    {session.title}
                                  </span>
                                )}
                              </TableCell>
                            )}
                          </TableRow>
                        </>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </div>
        )}
      </>
    </>
  );
};

export default AgendaBuilder;
