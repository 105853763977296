export const AUTH_TOKEN = "auth-token";
export const REFRESH_TOKEN = "refresh-token";

export const getToken = (token_name) => localStorage.getItem(token_name);
export const setToken = (token_name, token) =>
  localStorage.setItem(token_name, token);
export const deleteToken = (token_name) => localStorage.removeItem(token_name);

export const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};
