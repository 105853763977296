/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
// import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import { Grid, TextField } from '@mui/material';
import { NavLink, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';
import { IsEmail } from '../../Utils/Utils';
// import apiConfig from '../../Config/apiConfig';
// import { storeUserDetails, storeEmailId, redirectToSignup } from '../../store/home/actionCreator';
import { Helmet } from 'react-helmet';
import crossSvg from '../../Images/white_cross.svg';
import { useMutation } from '@apollo/client';
import { CHECK_EXISTING_USER } from '../../Utils/Mutations/Mutations';
// import popUpBg from '../../Images/popupbg.png';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

const styles = {
  customButton: {
    backgroundImage: '-webkit-linear-gradient(left,rgb(204,0,0), rgb(189,0,119))',
    fontSize: 14,
    padding: '12px 26px',
    borderRadius: 50,
    background: '#CC0000',
    color: '#fff',
    // fontFamily: 'HelveticaforTargetBold',
    fontWeight: 'bold',
    border: '2px solid #CC0000',
    cursor: 'pointer',
    '&:hover': {
      background: '#d61b32',
      boxShadow: 'none',
    },
    '&:focus': {
      background: '#d61b32',
      outline: '2px dotted #666666',
      outlineOffset: 2,
    },
    '&:disabled': {
      background: '#D6D6D6',
      color: '#333333',
      cursor: 'default',
    },
  },
  cancelButton: {
    fontSize: 14,
    padding: '12px 26px',
    borderRadius: 50,
    background: '#CC0000',
    color: '#fff',
    border: 'none',
    // fontFamily: 'HelveticaforTargetBold',
    fontWeight: 'bold',
    cursor: 'pointer',
    '&:hover': {
      // background: '#333333',
      boxShadow: 'none',
      color: '#FFF',
    },
    '&:focus': {
      background: '#333333',
      boxShadow: 'none',
      color: '#FFF',
      outline: '2px dotted #666666',
      outlineOffset: 2,
    },
  },
  root: {
    '& .MuiOutlinedInput-root': {
      height: 50,
      '& fieldset': {
        border: '2px solid #FFF',
        fontSize: 14,
        borderRadius: `50px`,
        // fontFamily: 'HelveticaforTargetRegular',
      },
      '&.Mui-focused': {
        borderRadius: `50px`,
        outline: '2px dotted #666666',
        outlineOffset: 2,
      },
    },
    '& .MuiOutlinedInput-input': {
      textIndent: 9,
      color: '#FFFFFF',
      // fontFamily: 'HelveticaforTargetRegular',
    },
    '& .MuiInputBase-input': {
      color: '#FFFFFF',
    },
  },
  errorLabel: {
    // fontFamily: 'HelveticaforTargetRegular',
    textAlign: 'center',
    color: '#EB0000',
    fontWeight: 'bold',
    fontSize: 14,
    marginTop: 16,
  },
  signUpText: {
    marginTop: 0,
    color: '#EB0000',
    // display: 'flex',
    // alignItems: 'center',
    // textAlign: 'center',
    // justifyContent: 'center',
    fontSize: '34px !important',
    // fontFamily: 'HelveticaforTargetBold',
    fontWeight: 'bold',

    marginBottom: 20,
    textAlign: 'center',
  },
  underLine: {
    border: '2px solid #be854c',
    backgroundColor: '#be854c',
    width: 80,
    marginBottom: 31,
  },
  messageLabel: {
    paddingTop: 10,
    color: '#EB0000',
    fontSize: 16,
    // fontFamily: 'HelveticaforTargetRegular',
    textAlign: 'left',
  },
  labelStyles: {
    fontSize: 22,
    // fontFamily: 'HelveticaforTarget-Light',
    marginBottom: 4,
    // marginLeft: 18,
    color: '#FFFFFF',
  },
  closeButton: {
    border: 'none',
    width: '20px',
    height: '20px',
    background: `url(${crossSvg}) no-repeat`,
    '&:focus-visible': {
      outline: '2px dotted #666666',
      outlineOffset: 2,
    },
    '&:hover': {
      opacity: 0.75,
      // backgroundColor: '#ffffff',
    },
  },
  dialog: {
    borderRadius: '25px',
    backgroundColor: 'rgb(247 244 244 / 78%) !important',
    '& .MuiDialog-paper': {
      maxWidth: 479,
    },
  },
  crossMark: {
    height: 17,
    width: 17,
    backgroundColor: '#ffffff',
    '&:hover': {
      opacity: 0.75,
      backgroundColor: '#fff',
    },
  },
};

function SignUp({
  storeEmailId,
  storeUserDetails,
  userSuccess = false,
  existingUser = false,
  isheader = false,
  openSignUp,
  setopenSignUp = () => {},
  isRedirect = false,
  // redirectToSignup,
  isSpotReg,
  isBanner,
  isDirectHit = false,
  handleDismiss = () => {},
}) {
  const [open, setOpen] = useState(false);
  const [emailId, setEmail] = useState('');
  const [isError, setError] = useState(false);
  const [message, setMessage] = useState('');
  const [checkExistingUser] = useMutation(CHECK_EXISTING_USER);
  const emailRef = useRef();
  const tab = useMediaQuery('(max-width:550px)');

  const mobile = useMediaQuery('(max-width:416px)');
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const navigate = useNavigate();

  const handleClickOpen = () => {
    //to open the pop up
    setOpen(true);
  };

  const handleClose = () => {
    handleDismiss();
    if (isMobile || isDirectHit) {
      setopenSignUp(false);
    }
    setOpen(false);
    setMessage('');
    setEmail('');
    setError('');
  };

  const handleChange = (e) => {
    setEmail(e.target.value);
    setError(false);
    setMessage('');
  };
  useEffect(() => {
    if (existingUser) {
      setMessage(
        <div
          role='alert'
          // className={styles.messageLabel}
          style={{
            width: mobile ? 223 : tab ? 300 : 375,
            color: '#be854c',
            fontWeight: 'bold',
            ...styles.messageLabel,
          }}
        >
          {`Thanks for updating your information.
                    We've already saved a seat for you. Check your Inbox for details soon and head to our `}
          <NavLink
            to='/gallery'
            style={{
              fontWeight: 'bold',
              color: '#0d6efd',
              cursor: 'pointer',
              textDecoration: 'none',
            }}
          >
            Gallery
          </NavLink>
          {` page for glimpses of past editions.`}
        </div>
      );
    } else if (userSuccess) {
      setMessage(
        <div
          role='alert'
          // className={styles.messageLabel}
          style={{
            width: mobile ? 223 : tab ? 300 : 375,
            color: '#FFFFFF',
            fontWeight: 'bold',
            ...styles.messageLabel,
          }}
        >
          {`Thank you for signing up for Target MeetUp 2023. You’ll hear from us real soon!`}
          {/* <NavLink
                        to='/gallery'
                        style={{ fontWeight: 'bold', color: '#333333', cursor: 'pointer', fontFamily: 'HelveticaforTargetBold', textDecoration: 'none' }}
                    >
                        Gallery
                    </NavLink>
                    {` page for glimpses of past editions.`} */}
        </div>
      );
    }
    window.scrollTo(0, 0);
  }, [userSuccess, existingUser]);

  useEffect(() => {
    if (isRedirect || isDirectHit) {
      if (isMobile || isDirectHit) {
        setopenSignUp(true);
        console.log('opening the pop up');
      }
      setOpen(true);
      // redirectToSignup(false);
    }
  }, [isRedirect]);

  const handleContinue = async (e) => {
    e.preventDefault();
    const lowerCaseMail = (emailId || '').toLowerCase();
    if (!IsEmail(lowerCaseMail)) {
      setError(true);
      emailRef.current.focus();
    } else {
      setError(false);
      try {
        const data = await checkExistingUser({
          variables: {
            emailId: lowerCaseMail,
          },
        });
        const responseData = data.data.checkExistingUser;

        if (responseData.message === 'New User') {
          console.log('new user block ', isSpotReg);
          // storeEmailId(lowerCaseMail);
          localStorage.setItem('usermailid', lowerCaseMail);
          setMessage('');
          if (isSpotReg) {
            navigate('/walkinregister?spotreg=true');
          } else {
            navigate('/register');
          }
          // navigate('/registerform');
          setOpen(false);
          if (isMobile || isDirectHit) {
            setopenSignUp(false);
          }
        } else if (responseData.message === 'Auto Approved') {
          setMessage(
            <div role='alert' style={{ width: mobile ? 223 : tab ? 300 : 375, ...styles.messageLabel }}>
              {`Looks like you're already signed up for Target MeetUp 2023.
                                        Check your Inbox for details, or write to us at `}
              <a
                style={{
                  fontWeight: 'bold',
                  color: '#CC0000',
                  textDecoration: 'none',
                }}
                href='mailto:meetup@target.com'
              >
                meetup@target.com.
              </a>
            </div>
          );
        } else if (responseData.message === 'approved but not registered') {
          // storeEmailId(lowerCaseMail);
          setMessage('');
          localStorage.setItem('userDetails', JSON.stringify(responseData.user));
          localStorage.setItem('usermailid', lowerCaseMail);
          // storeUserDetails(responseData.user);
          navigate('/register');
          setOpen(false);
          if (isMobile || isDirectHit) {
            setopenSignUp(false);
          }
        } else if (responseData.message === 'Rejected') {
          setMessage('');
          localStorage.setItem('userDetails', JSON.stringify(responseData.user));
          localStorage.setItem('usermailid', lowerCaseMail);
          navigate('/register');
          setOpen(false);
          if (isMobile || isDirectHit) {
            setopenSignUp(false);
          }
        } else {
          setMessage(
            <div role='alert' style={{ width: mobile ? 223 : tab ? 300 : 375, ...styles.messageLabel }}>
              {`Uh-oh! Looks like your invitation is not yet confirmed. Check back after you receive a confirmation. `}
              {/* <a style={{ fontWeight: 'bold', color: '#CC0000', textDecoration: 'none' }} href='mailto:elevate@target.com'>
                        elevate@target.com.
                    </a> */}
            </div>
          );
        }
      } catch (error) {
        console.log('Inside the erro', error);
        const { message = '' } = error;
        // setError(message);
        if (message === "We don't seem to have your details. Do check and try again.") {
          storeEmailId(lowerCaseMail);
          setMessage('');
          if (isSpotReg) {
            navigate('/register?spotreg=true');
          } else {
            navigate('/register');
          }
          setOpen(false);
          if (isMobile || isDirectHit) {
            setopenSignUp(false);
          }
        } else if (message === "Looks like you've already signed up for Meetup 2023. Check your inbox for details, or write to us multiplai@target.com.") {
          setMessage(
            <div role='alert' style={{ width: mobile ? 223 : tab ? 300 : 375, ...styles.messageLabel }}>
              {"Looks like you've already signed up for Meetup 2023. Check your inbox for details, or write to us "}
              <a
                style={{
                  fontWeight: 'bold',
                  color: '#EB0000',
                  textDecoration: 'none',
                }}
                href='mailto:meetup@target.com'
              >
                meetup@target.com.
              </a>
            </div>
          );
        }
      }
    }
  };
  return (
    <Grid>
      <Helmet>
        {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
        <script async src='https://www.googletagmanager.com/gtag/js?id=G-SRT1SJE7W0'></script>
        <script>
          {`  window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());

                        gtag('config', 'G-SRT1SJE7W0');`}
        </script>
      </Helmet>
      {userSuccess !== undefined ? (
        isheader ? null : (
          <div style={{ display: 'flex', justifyContent: 'left' }}></div>
        )
      ) : isMobile ? (
        isSpotReg && isBanner ? (
          <button style={styles.customButton} type='button' onClick={handleClickOpen}>
            {'Request an invitation'}
          </button>
        ) : null
      ) : (
        <button style={styles.customButton} type='button' onClick={handleClickOpen}>
          {'Request an invitation'}
        </button>
      )}
      {/* {isSpotReg ? (
        <button className={styles.customButton} type='button' onClick={handleClickOpen}>
          {'Request an invitation'}
        </button>
      ) : null} */}

      <Dialog
        // open={isMobile ? openSignUp : open}
        open={!isMobile || (isMobile && isSpotReg) ? open : openSignUp}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        sx={styles.dialog}
        aria-describedby='alert-dialog-slide-description'
        PaperProps={{
          style: {
            borderRadius: 25,
            width: '100%',
            // backgroundImage: `url(${popUpBg})`,
            backgroundColor: '#000000',
            backgroundPosition: 'bottom',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100% 22%',
            // border: '1px solid #eb0000',
          },
        }}
      >
        <div style={{ textAlign: 'right', padding: '10% 10% 0 0' }}>
          <button aria-label='close' onClick={() => handleClose()} style={styles.closeButton}></button>
        </div>
        <DialogContent
          style={{
            padding: tab || mobile ? '0px 24px 70px' : '20px 52px 70px',
          }}
        >
          <form onSubmit={(e) => handleContinue(e)} id='Sigup Form'>
            <h2 style={{ fontSize: tab ? 35 : 44, ...styles.signUpText }}>Register to Join</h2>
            {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div className={styles.underLine} />
                        </div> */}
            <div>
              <div style={{ textAlign: 'center', ...styles.labelStyles }}>
                {'Email Address'}
                <span aria-hidden='true' style={{ color: '#FFF' }}>
                  *
                </span>
              </div>
              <TextField
                type={'text'}
                name={'emailId'}
                placeholder={'Enter your email here'}
                onChange={(e) => handleChange(e)}
                value={emailId}
                style={{ width: '100%', padding: '20px 0' }}
                sx={styles.root}
                inputRef={emailRef}
              />
              {!isError ? null : <div style={styles.errorLabel}>{'Please enter a valid email address'}</div>}
              {!message ? null : message}
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: 16,
              }}
            >
              <button
                // className={styles.cancelButton}
                type='button'
                onClick={handleClose}
                style={{ padding: tab || mobile ? 14 : '12px 26px', ...styles.cancelButton }}
              >
                Cancel
              </button>
              <button
                // className={styles.customButton}
                type='submit'
                disabled={message ? true : false}
                style={{ padding: tab || mobile ? 14 : '12px 26px', ...styles.customButton }}
              >
                Continue
              </button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </Grid>
  );
}

const mapStateToProps = (state) => {
  // const homeData = state.home;
  return {
    userSuccess: '',
    existingUser: '',
    isRedirect: '',
  };
};

const mapDispatchToProps = {
  // storeUserDetails,
  // storeEmailId,
  // redirectToSignup,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
