import { Grid, useTheme } from "@mui/material";
// import { makeStyles } from '@mui/styles';
import LinkedInIcon from "@mui/icons-material/LinkedIn";
// import YouTubeIcon from '@mui/icons-material/YouTube';
// import TwitterIcon from '@mui/icons-material/Twitter';
import { Link } from "react-router-dom";
// import { useSelector } from 'react-redux';
import { Helmet } from "react-helmet";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";

const styles = (theme, isFaq = false) => {
  return {
    footerContainer: {
      //marginTop: '20px',
      // padding: '0 10%',
      //minHeight: '100px',
      backgroundColor: "#1A1A1A",
      color: "#FFF !important",
      position: isFaq ? "absolute" : "relative",
      bottom: "0",
      [theme.breakpoints.down("md")]: {
        bottom: "auto",
      },
    },
    socialIcons: {
      // backgroundColor: '#000',
      color: "#FFF",
      borderRadius: "50%",
      padding: "5px",
      fontSize: "2.3rem !important",
      margin: "0 10px",
    },
    footerLinks: {
      // padding: '20px 20px 10px 20px',
      color: "#FFF !important",
      textDecoration: "none",
      // textAlign: 'center',
      fontSize: "15px",
      // fontFamily: 'HelveticaforTargetRegular !important',
      margin: "auto 0 !important",
      [theme.breakpoints.down("md")]: {
        padding: "20px 20px 10px 20px",
      },
    },
    socialIconsContainer: {
      paddingTop: "20px",
    },
    links: {
      "&:focus-visible": {
        // background: '#d61b32',
        boxShadow: "none",
        outline: "2px dotted #666666",
        outlineOffset: 2,
      },
    },
  };
};
export default function Footer({ isPrivacy, isFaq }) {
  // const classes = useStyles({ isPrivacy, isFaq });
  const theme = useTheme();
  // const notificationStatus = useSelector((state) => state.home.notificationStatus);
  return (
    <footer>
      <Helmet>
        {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
        {/* <title>Target Multiplai - Register</title> */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-SRT1SJE7W0"></script>
        <script>
          {`  window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());

                        gtag('config', 'G-SRT1SJE7W0');`}
        </script>
        <script>
          {`_linkedin_partner_id = "3079162";
                        window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
                        window._linkedin_data_partner_ids.push(_linkedin_partner_id);`}
        </script>
        <script>
          {`(function(l) {
                            if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
                            window.lintrk.q=[]}
                            var s = document.getElementsByTagName("script")[0];
                            var b = document.createElement("script");
                            b.type = "text/javascript";b.async = true;
                            b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
                            s.parentNode.insertBefore(b, s);})(window.lintrk);
                           `}
        </script>
        <noscript>{`<img height='1' width='1' style='display:none;' alt='' src='https://px.ads.linkedin.com/collect/?pid=3079162&fmt=gif' />`}</noscript>
      </Helmet>
      <Grid container sx={styles(theme, isFaq).footerContainer}>
        {/* <Grid item md={1}></Grid> */}
        <Grid item md={2} xs={12} sx={styles(theme).footerLinks}>
          <Link
            to="/terms"
            style={{
              color: "#FFF",
              textDecoration: "none",
              fontSize: "15px",
              ...styles(theme).links,
            }}
          >
            Terms and Conditions
          </Link>
        </Grid>
        <Grid item md={2} xs={12} sx={styles(theme).footerLinks}>
          <Link
            to="/privacy"
            style={{
              color: "#FFF",
              textDecoration: "none",
              fontSize: "15px",
              ...styles(theme).links,
            }}
          >
            Privacy Policy
          </Link>
        </Grid>
        <Grid item md={2} xs={12} sx={styles(theme).footerLinks}>
          <a
            href="mailto:meetup@target.com"
            style={{
              color: "#FFF",
              textDecoration: "none",
              fontSize: "15px",
              ...styles(theme).links,
            }}
          >
            Contact Us
          </a>
        </Grid>
        <Grid item md={3} xs={12} sx={styles(theme).footerLinks}>
          &copy; 2024 Target Brands, Inc.
        </Grid>
        <Grid item md={2} xs={12} sx={styles(theme).footerLinks} style={{}}>
          {/* <a
                        href='https://twitter.com/TeamTargetIndia'
                        target='_blank'
                        // rel='noopener noreferrer'
                        aria-label={notificationStatus ? '' : 'Follow us on twitter'}
                        className={styles(theme).links}
                        rel='noreferrer'
                    >
                        <TwitterIcon className={styles(theme).socialIcons} />
                    </a> */}
          <a
            href="https://www.linkedin.com/company/target/posts/?feedView=all"
            target="_blank"
            // rel='noopener noreferrer'
            aria-label={"Follow us on LinkedIn"}
            style={styles(theme).links}
            rel="noreferrer"
          >
            <LinkedInIcon sx={styles(theme).socialIcons} />
          </a>
          <a
            href="https://instagram.com/teamtargetinindia"
            target="_blank"
            // rel='noopener noreferrer'
            aria-label={"Follow us on Instagram"}
            style={styles(theme).links}
            rel="noreferrer"
          >
            <InstagramIcon sx={styles(theme).socialIcons} />
          </a>
          <a
            href="https://twitter.com/TeamTargetIndia"
            target="_blank"
            // rel='noopener noreferrer'
            aria-label={"Follow us on Twitter"}
            style={styles(theme).links}
            rel="noreferrer"
          >
            <TwitterIcon sx={styles(theme).socialIcons} />
          </a>
          {/* <a
                        href='https://www.youtube.com/c/targetelevate'
                        target='_blank'
                        // rel='noopener noreferrer'
                        aria-label={notificationStatus ? '' : 'Follow us on youtube'}
                        className={styles(theme).links}
                        rel='noreferrer'
                    >
                        <YouTubeIcon className={styles(theme).socialIcons} />
                    </a> */}
        </Grid>
      </Grid>
    </footer>
  );
}
