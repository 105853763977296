import { gql } from '@apollo/client';

export const GET_SESSIONS_FOR_LOGGED_IN_USER = gql`
  query getSessions {
    rawEvents(where: { archived: { equals: false } }) {
      id
      name
      description
      event_url
      end_time
      start_time
      session {
        isfinale
        streaming_url
        id
        title
        description
        isFavorited
        viewing_url
        speaker {
          fullName
          designation
          organisation
          image_url
        }
        start_time
        end_time
      }
    }
  }
`;
export const GET_SESSIONS_FOR_NON_LOGGED_IN_USER = gql`
  query getSessions {
    rawEvents(where: { archived: { equals: false } }) {
      name
      description
      end_time
      start_time
      session {
        isfinale
        title
        description
        speaker {
          fullName
          designation
          organisation
          image_url
        }
        start_time
        end_time
      }
    }
  }
`;

export const GET_NOTIFICATIONS = gql`
  query alertsWithLabel {
    alerts(orderBy: { created_at: desc }, where: { created_at: { gte: "2022-03-01T00:00:00.000Z" } }) {
      payload
      title
      created_at
      id
      body
    }
  }
`;

export const LOGGED_IN_USER_PROFILE = gql`
  query profile {
    profile {
      id
      first_name
      last_name
      contact_no
      email_id
      fullName
      organisation
      designation
      about_user
      linkedin_profile_url
      exp_years
      created_at
      advertisement_channel
    }
  }
`;

export const GET_FAVOURITE_SESSIONS = gql`
  query getSessions {
    rawEvents(where: { archived: { equals: false } }) {
      id
      name
      description
      event_url
      end_time
      start_time
      session {
        isfinale
        streaming_url
        id
        title
        description
        isFavorited
        viewing_url
        speaker {
          fullName
          designation
          organisation
          image_url
        }
        start_time
        end_time
      }
    }
  }
`;

export const GET_PERCENTAGE_USERS = gql`
  query getSurveyDetails {
    surveyDetails
  }
`;
export const GET_YEAR_WISE_SESSIONS = gql`
  query {
    rawEvents {
      name
      start_time
      end_time
      session {
        title
        viewing_url_title
        caption_url
        transcript_url
        description
        banner_image_url
        speaker {
          fullName
          designation
          contract_end_date
        }
      }
    }
  }
`;

export const GET_SPEAKERS = gql`
  query {
    speakers(orderBy: { priority: asc }) {
      fullName
      designation
      organisation
      about_user
      show_on_website
      image_url
      event_year
      contract_end_date
    }
  }
`;

export const GET_AGENDA = gql`
  query getSessions {
    rawEvents(where: { archived: { equals: false } }) {
      name
      description
      end_time
      start_time
      session {
        isfinale
        title
        description
        speakers {
          title
          fullName
          designation
          organisation
          image_url
          about_user
        }
        start_time
        end_time
      }
    }
  }
`;

export const GET_USER_DETAILS = gql`
  query {
    totalUsers {
      totalUsersCount
      admittedUsersCount
      walkinRegistrationsCount
      registeredUsers
      workshopsSeatCount
      remainingWalkinCount
      approvedUsersCount
      admittedInternalUsersCount
      admittedExternalUsersCount
      walkinInternalUsersCount
      walkinExternalUsersCount
    }
  }
`;

export const GET_WORKSHOPS = gql`
  query {
    sessions(where: { type: workshop, archived: { equals: false } }, orderBy: { created_at: asc }) {
      id
      type
      title
      seatsRemaining
      speakers {
        fullName
        title
        designation
        organisation
      }
    }
  }
`;

export const GET_IBPSUSERS = gql`
  query {
    ibps {
      id
      email
      is_admitted
      contact_no
      first_name
      company
    }
  }
`;
