export const desktopBg = 'https://gfc.target.com/elevatewebsiteproduction/elevatewebsite2022_1_Header_option.png';
export const desktopHeaderWomanGraphic = 'https://gfc.target.com/elevatewebsiteproduction/elevatewebsite2022_1_Header_WomanGraphic.png';
export const mobileHeaderWomanGraphic = 'https://gfc.target.com/elevatewebsiteproduction/elevatewebsite2022_1_Header_WomanGraphic_Mobile_latest.png';
export const desktopHeaderGraphic = 'https://gfc.target.com/elevatewebsiteproduction/elevatewebsite2022_1_Header_Graphic_Left.png';
export const logo = 'https://gfc.target.com/elevatewebsiteproduction/elevatewebsite2022_TargetElevate_newlogo.png';
export const privacyBgImg = 'https://gfc.target.com/elevatewebsiteproduction/elevatewebsite2022_PrivacyPolicy_Graphic.jpg';
export const tandcLeftImg = 'https://gfc.target.com/elevatewebsiteproduction/elevatewebsite2022_T&CGraphic2.png';
export const tandcRightImg = 'https://gfc.target.com/elevatewebsiteproduction/elevatewebsite2022_T&C_Graphic1.jpg';
export const loader = 'https://gfc.target.com/elevatewebsiteproduction/elevatewebsite2022_Elevate2022_Loader.gif';
export const TickMark = 'https://gfc.target.com/elevatewebsiteproduction/Tick-Mark-Once.gif';
export const crossSvg = 'https://gfc.target.com/elevatewebsiteproduction/cross.svg';
export const whiteCrossSvg = 'https://gfc.target.com/elevatewebsiteproduction/white-cross.svg';
export const womenInTechBg = 'https://gfc.target.com/elevatewebsiteproduction/WomenInTech.jpg';
export const highlightBg = 'https://gfc.target.com/elevatewebsiteproduction/highlight.jpg';
export const womenInTechMobileImg = 'https://gfc.target.com/elevatewebsiteproduction/WomenInTechBg.png';
export const addToCalSvg = 'https://gfc.target.com/elevatewebsiteproduction/Elevate_Calendar_Icon_new.svg';
export const joinNowSvg = 'https://gfc.target.com/elevatewebsiteproduction/Elevate_Join_Icon.svg';
export const ElevateNotificationsIcon = 'https://gfc.target.com/elevatewebsiteproduction/ElevateNotificationsIcon.png';
export const ElevateNotificationsIconGrey = 'https://gfc.target.com/elevatewebsiteproduction/ElevateNotificationsIconGrey.png';
export const ElevateSurveyInfoIcon = 'https://gfc.target.com/elevatewebsiteproduction/Elevate_Survey_Info_Icon.png';
export const ElevateSurveyGradient = 'https://gfc.target.com/elevatewebsiteproduction/Elevate_Survey_Gradient.png';
export const LogoForSurveyPage = 'https://gfc.target.com/elevatewebsiteproduction/Elevatelogo_Survey@2x.png';
// multiplai images
export const multiplaiLogo = 'https://gfc.target.com/multiplai/Logo.png';
export const popUpBgImage = 'https://gfc.target.com/multiplai/popupbg.png';
export const privacyPolicyBg = 'https://gfc.target.com/multiplai/Privicy%20without%20background.png';
export const speakersBg = 'https://gfc.target.com/multiplai/SpeakerGraphic.png';
export const tAndcBg = 'https://gfc.target.com/multiplai/TandC_Graphic.png';
export const desktopAnimation = 'https://gfc.target.com/multiplai/animationVideo.mp4';
export const mobileAnimation = 'https://gfc.target.com/multiplai/Web-Banner-Square.mp4';
export const DottedWebBack = 'https://gfc.target.com/multiplai/bottom.png';

// meetup images
export const meetup_logo = 'https://gfc.target.com/multiplai/meetup/meetup_logo.png';
