import { Helmet } from "react-helmet";
import AgendaBuilder from "./AgendaBuilder";
import AdTechAgendaData from "./AdTechAgenda.json";
const AdTechAgenda = () => {
  return (
    <>
      <Helmet>
        {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
        <title>Target Meetup - android agenda</title>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-SRT1SJE7W0"></script>
        <script>
          {`  window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());

                        gtag('config', 'G-SRT1SJE7W0');`}
        </script>
        <script>
          {`_linkedin_partner_id = "3079162";
                        window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
                        window._linkedin_data_partner_ids.push(_linkedin_partner_id);`}
        </script>
        <script>
          {`(function(l) {
                            if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
                            window.lintrk.q=[]}
                            var s = document.getElementsByTagName("script")[0];
                            var b = document.createElement("script");
                            b.type = "text/javascript";b.async = true;
                            b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
                            s.parentNode.insertBefore(b, s);})(window.lintrk);
                           `}
        </script>
        <noscript>{`<img height='1' width='1' style='display:none;' alt='' src='https://px.ads.linkedin.com/collect/?pid=3079162&fmt=gif' />`}</noscript>
      </Helmet>
      <div>
        <AgendaBuilder agendaData={AdTechAgendaData} />
      </div>
    </>
  );
};

export default AdTechAgenda;
