import { useSelector } from "react-redux";
import typo from "../../assets/json/typho.json";

export default function _findFontSize(typoVariant, sizeVariant, device, responsive = false) {
  var fontsizeVal = {
    sizeVal: "",
  };
  const typosFromStencil = useSelector((state) => state.tenant.typo);
  const typos = typosFromStencil === undefined ? typo : typosFromStencil;

  typos?.length > 0 &&
    typos.forEach((variant) => {
      if (variant.slug === typoVariant) {
        variant.fontSize.forEach((sizes) => {
          if (sizes.name === sizeVariant) {
            sizes.values.forEach((fontSize) => {
              if (fontSize.name === device) {
                fontsizeVal.sizeVal = responsive
                  ? fontSize.range.viewportSize
                  : fontSize.range.value
                  ? fontSize.range.value
                  : fontSize.range.default;
              }
            });
          }
        });
      }
    });
  return fontsizeVal;
}
