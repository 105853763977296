import { useEffect, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Box,
  Dialog,
  DialogContent,
  FormControlLabel,
  Grid,
  Modal,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useTenantContext } from "enterprise-stencil";
import {
  IsValidDesignation,
  IsValidFirstName,
  IsValidLastName,
  IsValidLinkedInProfile,
  IsValidMobileNo,
  IsValidBio,
} from "../../Utils/Utils";
import Popup from "./Popup";
import { whiteCrossSvg, r_form_bg } from "../../assets/images/Images";
import SignUpPopup from "./SignUpPopup";
import { useRegisterUserMutation, useUpdateYtrUserMutation } from "../../api/registration";
import { setEventId, useGetActiveEventsQuery } from "../../api/tenant";
import { useUnsubscribeUserMutation } from "../../api/unsubscribe";
import { setEmailId } from "../../api/validateUser";

const useStyles = makeStyles((theme) => ({
  container: {
    // background: 'white',
    zIndex: 1,
  },
  header: {
    alignItems: "center",
    justifyContent: "space-between",
    display: "flex",
  },
  register: {
    fontWeight: 600,
    fontSize: 32,
    margin: "30px 0 12px 0",
    color: "#333333",
    textAlign: "center",
  },
  modalBody: {
    fontWeight: 600,
    width: "100%",
    padding: "30px 0 22px 18px",
    color: "#EB0000",
    fontSize: 20,
    fontFamily: "HelveticaForTarget",
  },
  labelStyles: {
    fontSize: 16,
    marginBottom: 4,
    // marginLeft: 18,
    // width: '150%',
    color: "#FFFFFF",
    fontFamily: "HelveticaForTarget",
  },
  areasOfInterest: {
    fontSize: 16,
    marginBottom: 4,
    marginLeft: 18,
    color: "#6d6d6d",
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "0 !important",
      backgroundColor: "#000000 !important",
    },
  },
  inputStyles: {
    border: "1px solid #FFFFFF",
    backgroundColor: "#000000",
    borderRadius: 10,
    fontSize: 14,
    fontFamily: "HelveticaForTarget",
    // width: 330,
    padding: "14px 20px",
    color: "#FFFFFF",
    marginBottom: 4,
    "&:focus": {
      outline: "2px dotted #666666",
      outlineOffset: 2,
    },
    "&:disabled": {
      backgroundColor: "#222222",
      opacity: 1,
    },
    "&::placeholder": {
      color: "#777777",
    },
  },
  errorLabel: {
    fontWeight: 600,
    marginLeft: 18,
    color: "#e92e1a",
    fontSize: 14,
    marginBottom: 6,
    width: "100%",
  },
  customButton: {
    backgroundColor: "#cc0000",
    fontSize: 14,
    padding: "12px 26px",
    marginBottom: 11,
    borderRadius: 50,
    background: "#CC0000",
    color: "#fff",
    minWidth: 125,
    // fontFamily: 'HelveticaforTargetBold',
    fontWeight: 600,
    border: "none",
    cursor: "pointer",
    "&:hover": {
      background: "#d61b32",
      boxShadow: "none",
    },
    "&:focus": {
      background: "#d61b32",
      outline: "2px dotted #666666",
      outlineOffset: 2,
    },
    "&:disabled": {
      border: "1px solid #999999",
      color: "#FFF",
      backgroundColor: "#666666",
      backgroundImage: "none",
    },
  },
  root: {
    "& .MuiOutlinedInput-root": {
      width: 200,
      height: 50,
      fontSize: 14,
      marginBottom: 4,
      "& fieldset": {
        borderRadius: `10px`,
        border: "1px solid #FFFFFF",
        fontSize: 14,
      },
    },
    "& .Mui-focused": {
      borderRadius: `50px`,
      outline: "2px dotted #666666",
      outlineOffset: 2,
    },
    "& .MuiOutlinedInput-input": {
      // color: '#666666',
      // color: (props) => (props.colorFlag ? '#FFF' : '#666666'),
      color: "#FFF",
      textIndent: 9,
    },
    "& .MuiSvgIcon-fontSizeMedium": {
      color: "#CC0000",
    },
  },
  prefix: {
    position: "relative",
    left: 16,
    top: "-38.5px",
    width: 0,
    fontSize: 14,
  },
  prefixNumber: {
    display: "none",
    color: "#FFF",
    paddingTop: "3px",
  },
  closeButton: {
    border: "none",
    width: "20px",
    height: "20px",
    padding: 0,
    background: `url(${whiteCrossSvg}) no-repeat`,
    "&:focus-visible": {
      //background: '#D6D6D6',
      outline: "2px dotted #666666",
      outlineOffset: 2,
    },
    "&:hover": {
      opacity: 0.75,
    },
  },
  alertCloseButton: {
    border: "none",
    width: "20px",
    height: "20px",
    cursor: "pointer",
    background: `url(${whiteCrossSvg}) no-repeat`,
    "&:focus-visible": {
      outline: "2px dotted #666666",
      outlineOffset: 2,
    },
    "&:hover": {
      opacity: 0.75,
      // backgroundColor: '#ffffff',
    },
  },
  radioButton: {
    color: "#FFF !important",
    "&.Mui-checked": {
      color: "#CC0000 !important",
    },
  },
  workshop1RadioButton: {
    color: "#000 !important",
    "&.Mui-checked": {
      color: "#000 !important",
    },
  },
  workshop2RadioButton: {
    color: "#000 !important",
    "&.Mui-checked": {
      color: "#000 !important",
    },
  },
}));

const InputField = ({
  label = "",
  name = "",
  type = "text",
  required = false,
  placeholder = "",
  value = "",
  handleChange,
  classes,
  errorLabel = "",
  disabled = false,
  isError = "",
  inputRef,
  isSpotReg = false,
  isFullWidthField = false,
}) => {
  return (
    <Grid item lg={isFullWidthField ? 12 : 6} md={12} sm={12}>
      {!label ? (
        <div style={{ marginTop: 10 }} />
      ) : (
        <div className={classes.labelStyles}>
          {label}
          <span aria-hidden="true" style={{ color: "#FFF" }}>
            {isSpotReg ? null : "*"}
          </span>
        </div>
      )}
      <input
        type={type}
        rows={4}
        name={name}
        placeholder={placeholder}
        ref={inputRef}
        // required={required}
        onChange={handleChange}
        value={value}
        className={classes.inputStyles}
        disabled={disabled}
        aria-required={required}
      />
      {!isError ? (
        name === "linkedInProfile" ? (
          <div style={{ marginBottom: 0 }}>
            <span style={{ color: "#FFF", fontSize: "13px" }}>www.linkedin.com/in/username</span>
          </div>
        ) : (
          <div style={{ marginBottom: 22 }} />
        )
      ) : (
        <div className={classes.errorLabel}>{isError}</div>
      )}
    </Grid>
  );
};

const MobileNoInputField = ({
  label = "",
  name = "",
  type = "text",
  required = false,
  placeholder = "",
  value = "",
  handleChange,
  classes,
  errorLabel = "",
  disabled = false,
  isError = "",
  inputRef,
}) => {
  const [isNumber, setNumber] = useState(value ? true : false);

  useEffect(() => {
    if (value) {
      setNumber(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid item lg={6} md={12} sm={12}>
      {!label ? (
        <div style={{ marginTop: 10 }} />
      ) : (
        <div className={classes.labelStyles}>
          {label}{" "}
          <span aria-hidden="true" style={{ color: "#FFF" }}>
            *
          </span>
        </div>
      )}
      <input
        type={type}
        name={name}
        placeholder={isNumber ? "" : placeholder}
        ref={inputRef}
        // required={required}
        onChange={handleChange}
        value={value}
        className={classes.inputStyles}
        disabled={disabled}
        aria-required={required}
        style={{ textIndent: isNumber ? "22px" : 0 }}
        onKeyPress={() => setNumber(true)}
      />
      <div className={classes.prefix}>
        <div className={classes.prefixNumber} style={{ display: isNumber ? "block" : "none" }}>
          +91
        </div>
      </div>
      {!isError ? (
        <div style={{ marginBottom: isNumber ? 0 : 22 }} />
      ) : (
        <div className={classes.errorLabel}>{isError}</div>
      )}
    </Grid>
  );
};

const Registration = ({
  user = "",
  existingUser,
  storeUserDetails,
  storeRegisterSuccess,
  storeEmailId,
  storeExistingUser,
}) => {
  // 2024 changes
  const [registerUser, { data: registerUserResponse, error: registerUserResError }] = useRegisterUserMutation();
  const [updateYtrUser, { data: updateYtrUserResponse, error: updateYtrUserResError }] = useUpdateYtrUserMutation();
  const [unsubscribeUser, { data: unsubscribeUserResponse, error: unsubscribeUserResError }] =
    useUnsubscribeUserMutation();
  const eventId = useSelector((state) => state.tenant.eventId);
  const emailId = useSelector((state) => state.user.email_id);
  const [isRegError, setIsRegError] = useState(false);
  const [isDisableRegBtn, setIsDisableRegBtn] = useState(false);
  const [isOtherRole, setIsOtherRole] = useState(false);
  const existingUserData = useSelector((state) => {
    return {
      ...state.user.meta,
      ...state.user.member,
      ...state.user.status,
    };
  }, shallowEqual);
  const dispatch = useDispatch();

  // 2024 changes end
  const navigate = useNavigate();
  const mobile = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  // const [registerUser, { data: registerUserData }] = useMutation(REGISTER_USER);
  // const [spotRegisterUser] = useMutation(SPOT_REGISTER_USER);
  const [colorFlag, setColorFlag] = useState(false);
  const classes = useStyles({ colorFlag });
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    mobileNo: "",
    email: "",
    houseNo: "",
    additionalDetails: "",
    landmark: "",
    city: "",
    state: "",
    pincode: "",
    optedForKit: false,
    companyName: "",
    designation: "",
    focusArea: "",
    experience: "",
    linkedInProfile: "",
    describeYourRole: "",
    explainYourRole: "",
    advertisementChannel: "",
    isError: false,
    approved: false,
    registrationSuccess: false,
    roleSelection: "",
    id: "",
    isConsent: "",
    otherRole: "",
  });
  const [isAdmin, setIsAdmin] = useState(false);
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const mobileNoRef = useRef();
  const companyNameRef = useRef();
  const designationRef = useRef();
  const linkedInProfileRef = useRef();
  const describeYourRoleRef = useRef();
  const consentOptionRef = useRef();
  const explainYourRole = useRef();
  const [errors, setFieldErrors] = useState({
    firstNameError: "",
    lastNameError: "",
    companyNameError: "",
    designationError: "",
    linkedInProfileError: "",
    describeYourRoleError: "",
    mobileNoError: "",
    consentError: "",
    // experienceError: "",
  });

  const [areas, setAreas] = useState([
    {
      value: "engineeringArea",
      label: "Engineering",
      checked: false,
    },
    {
      value: "productDevelopmentArea",
      label: "Product Development",
      checked: false,
    },
    {
      value: "infrastructureArea",
      label: "Infrastructure",
      checked: false,
    },
    {
      value: "informationSecurityArea",
      label: "Information Security",
      checked: false,
    },
    {
      value: "dataSciencesArea",
      label: "Data Sciences",
      checked: false,
    },
    {
      value: "innovationEmergingArea",
      label: "Innovation or Emerging Technologies",
      checked: false,
    },
  ]);
  const [showRoleTextBox, setShowRoleTextBox] = useState(false);
  const { tenantId } = useTenantContext();
  const { data: eventsData, error: eventsError } = useGetActiveEventsQuery({ site_id: tenantId }, { skip: !tenantId });
  // const [openRegDialog, setOpenRegDialog] = React.useState(false);
  useEffect(() => {
    if (eventsData && eventsData.length > 0) {
      dispatch(setEventId({ eventId: eventsData[0]._id }));
      localStorage.setItem("eventId", JSON.stringify(eventsData[0]._id));
    }
  }, [dispatch, eventsData, eventsError]);

  //SPOT REGISTRATION CHANGES STARTS HERE
  // const [getWorkshops, { data: workshopsData, refetch: workshopsRefetch }] = useLazyQuery(GET_WORKSHOPS);
  // const [getUserDetails, { data: usersData }] = useLazyQuery(GET_USER_DETAILS);
  // useEffect(async () => {
  //   try {
  //     await getWorkshops();
  //   } catch (error) {
  //     console.log(error.message);
  //   }
  // }, [workshopsData]);
  // useEffect(async () => {
  //   try {
  //     await getUserDetails();
  //   } catch (error) {
  //     console.log(error.message);
  //   }
  // }, [usersData]);

  // eslint-disable-next-line no-unused-vars
  const [workshopRadioBtn, setWorkshopRadioBtn] = useState("");
  const handleWorkshopRadioBtn = (e) => {
    setWorkshopRadioBtn(e.target.value);
    // setTimeout(workshopsRefetch, 300);
  };

  // Alert box code starts
  const [openAlertBox, setOpenAlertBox] = useState(false);
  const handleOpenAlertBox = () => {
    setOpenAlertBox(true);
  };
  const handleCloseAlertBox = () => setOpenAlertBox(false);

  const [alertMsg, setAlertMsg] = useState("");
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  // Alert box code ends

  //SPOT REGISTRATION CHANGES ENDS HERE

  useEffect(() => {
    if (!emailId) {
      // navigate('/');
      setopenSignUp(true);
      return;
    }

    const tempObj = {};
    if (
      existingUserData.member &&
      existingUserData[0]?.year === 2024 &&
      existingUserData[0]?.status === "YET_TO_REGISTER"
    ) {
      // this is correctif
      // if (existingUserData.member && !(existingUserData[0] && existingUserData[0]?.status === 'REJECTED')) {
      const userName = existingUserData["name"].split(" ");
      tempObj["first_name"] = userName[0];
      tempObj["last_name"] = userName[1];
      tempObj["organisation"] = existingUserData["company"];
      tempObj["contact_no"] = existingUserData["mobile"];
      tempObj["designation"] = existingUserData["designation"];
      tempObj["exp_years"] = existingUserData["experience"];
      tempObj["linkedin_profile_url"] = existingUserData["linkedin_profile_url"];
      tempObj["about_user"] = existingUserData["about_user"];
      tempObj["advertisement_channel"] = existingUserData["advertisement_channel"];
      tempObj["areasOfInterest"] = existingUserData["areas_of_interest"];
      tempObj["areas_of_interest_level2"] = existingUserData["areas_of_interest_level2"];
      tempObj["city"] = existingUserData["city"];
      tempObj["state"] = existingUserData["state"];
    }

    const {
      first_name = "",
      last_name = "",
      contact_no = "",
      opted_for_kit = false,
      organisation = "",
      designation = "",
      focusArea = "",
      expYears = "",
      linkedin_profile_url = "",
      about_user = "",
      advertisement_channel = "",
      areasOfInterest = "",
      registration_success = false,
      approved = false,
      id = "",
      email_id = "",
      isConsent = "",
    } = tempObj;

    if (areasOfInterest) {
      const newAreasofInterest = [...areas];
      const interests = areasOfInterest.split(",");
      areas.forEach((value, index) => {
        interests.forEach((item) => {
          if (item === value.value.trim() || item === value.label.trim()) {
            newAreasofInterest[index].checked = true;
          }
        });
      });
      setAreas(newAreasofInterest);
    }

    setUserData({
      firstName: first_name,
      lastName: last_name,
      mobileNo: contact_no,
      email: email_id ? email_id : emailId,
      optedForKit: opted_for_kit,
      companyName: organisation,
      designation: designation,
      focusArea: focusArea,
      experience: expYears,
      linkedInProfile: linkedin_profile_url,
      describeYourRole: about_user,
      advertisementChannel: advertisement_channel,
      approved: approved,
      registrationSuccess: registration_success,
      id: id,
      isConsent: isConsent,
    });
  }, [user, emailId, areas, existingUserData]);

  // we are recording the AX for the users who click on consent radio buttons
  useEffect(() => {
    if (process.env.REACT_APP_ENV === "prod") {
      try {
        setTimeout(() => {
          document.getElementById("Yes_consent_form_click").addEventListener("click", function () {
            // eslint-disable-next-line no-undef
            gtag("event", "Yes_consent_form_click", {
              event_category: "Button Click",
              event_label: "Clicked on Yes consent",
              button_type: "CTA",
            });
          });
          document.getElementById("No_consent_form_click").addEventListener("click", function () {
            // eslint-disable-next-line no-undef
            gtag("event", "No_consent_form_click", {
              event_category: "Button Click",
              event_label: "Clicked on No consent",
              button_type: "CTA",
            });
          });
        }, 5000);
      } catch (error) {
        console.log("Error ", error);
      }
    }
  }, []);

  const handleRadioChange = (e) => {
    if (e.target.value === "Other") {
      setIsOtherRole(true);
    } else {
      setIsOtherRole(false);
    }
  };

  const handleChange = (e) => {
    const name = e.target.name;
    if ((e.target.name === "experience" || e.target.name === "advertisementChannel") && e.target.value !== "") {
      setColorFlag(true);
    } else {
      setColorFlag(false);
    }
    if (name === "isConsent") {
      setUserData({
        ...userData,
        [name]: e.target.value,
      });
    }
    if (name === "explainYourRole") {
      setUserData({
        ...userData,
        [name]: e.target.value,
      });
    }
    if (name === "mobileNo") {
      let value = e.target.value.replace(/\D/g, "");
      if (value.length > 10) {
        value = value.slice(0, 10);
      } else {
        // eslint-disable-next-line no-self-assign
        value = value;
      }
      setUserData({
        ...userData,
        [name]: value,
      });
    } else if (name === "pincode") {
      let value = e.target.value.replace(/\D/g, "");
      if (value.length > 6) {
        value = value.slice(0, 6);
      } else {
        // eslint-disable-next-line no-self-assign
        value = value;
      }
      setUserData({
        ...userData,
        [name]: value,
      });
    }
    // else if (name === "describeYourRole") {
    //   const value = e.target.value;
    //   const no_words = value.split(" ");
    //   console.log("value ", value);
    //   setUserData({
    //     ...userData,
    //     [name]: value,
    //   });
    //   if (no_words.length <= 50) {
    //     console.log("no_words.length <= 50 if");
    //     setFieldErrors({
    //       ...errors,
    //       describeYourRoleError: "",
    //     });
    //   } else {
    //     console.log("no_words.length <= 50 else");
    //     setFieldErrors({
    //       ...errors,
    //       describeYourRoleError: "Should be 50 words or less",
    //     });
    //   }
    // }
    else if (name === "houseNo" || name === "additionalDetails") {
      const value = e.target.value.replace(/[^a-zA-Z0-9 .,-]/gi, "");
      setUserData({
        ...userData,
        [name]: value,
      });
    } else if (name === "describeYourRole") {
      if (e.target.value === "Others") {
        setShowRoleTextBox(true);
        setUserData({
          ...userData,
          [name]: e.target.value,
        });
      } else {
        setUserData({
          ...userData,
          roleSelection: e.target.value,
        });
        setShowRoleTextBox(false);
      }
    } else {
      const value = e.target.value;
      setUserData({
        ...userData,
        [name]: value,
      });
    }
  };

  const validations = () => {
    const {
      firstName,
      mobileNo,
      lastName,
      describeYourRole,
      companyName,
      designation,
      linkedInProfile,
      roleSelection,
      isConsent,
    } = userData;

    const newErrors = { ...errors };

    if (!isConsent) {
      newErrors.consentError = "Please choose your consent";
    } else {
      newErrors.consentError = "";
    }
    if (!firstName) {
      newErrors.firstNameError = "Please provide your first name";
    } else if (!IsValidFirstName(firstName)) {
      newErrors.firstNameError = "Please provide a valid first name";
    } else {
      newErrors.firstNameError = "";
    }
    if (!lastName) {
      newErrors.lastNameError = "Please provide your last name";
    } else if (!IsValidLastName(lastName)) {
      newErrors.lastNameError = "Please provide a valid last name";
    } else {
      newErrors.lastNameError = "";
    }
    if (!mobileNo) {
      newErrors.mobileNoError = "Please provide your mobile number";
    } else if (!IsValidMobileNo(mobileNo)) {
      newErrors.mobileNoError = "Please provide a valid mobile number";
    } else {
      newErrors.mobileNoError = "";
    }
    if (!describeYourRole & !roleSelection) {
      newErrors.describeYourRoleError = "Please describe your role";
    } else if (!IsValidBio(describeYourRole) & isOtherRole) {
      newErrors.describeYourRoleError =
        "Uh-oh, the only special characters accepted are the full-stop, hyphen and comma.";
    } else if ((describeYourRole.length < 25) & isOtherRole) {
      newErrors.describeYourRoleError = "Min 25 characters";
    } else {
      newErrors.describeYourRoleError = "";
    }

    if (!companyName) {
      newErrors.companyNameError = "Please provide your company name";
    } else if (!IsValidLastName(companyName)) {
      newErrors.companyNameError = "Please provide a valid company name";
    } else {
      newErrors.companyNameError = "";
    }
    if (!designation) {
      newErrors.designationError = "Please provide your designation";
    } else if (!IsValidDesignation(designation)) {
      newErrors.designationError = "Please provide a valid designation";
    } else {
      newErrors.designationError = "";
    }
    if (!IsValidLinkedInProfile(linkedInProfile) && !isSpotReg) {
      newErrors.linkedInProfileError = "Please provide a valid linkedIn profile";
    } else {
      newErrors.linkedInProfileError = "";
    }
    // if(!is)
    setFieldErrors(newErrors);
    const isError = Object.values(newErrors).some((k) => k !== "");
    newErrors.firstNameError
      ? firstNameRef.current.focus()
      : newErrors.lastNameError
      ? lastNameRef.current.focus()
      : newErrors.mobileNoError
      ? mobileNoRef.current.focus()
      : newErrors.companyNameError
      ? companyNameRef.current.focus()
      : newErrors.designationError
      ? designationRef.current.focus()
      : newErrors.linkedInProfileError
      ? linkedInProfileRef.current.focus()
      : newErrors.describeYourRoleError
      ? describeYourRoleRef.current.focus()
      : !newErrors.consentError && consentOptionRef.current.focus();

    return isError;
  };

  useEffect(() => {
    if (registerUserResponse?.status || updateYtrUserResponse?.status || unsubscribeUserResponse?.status) {
      setOpen(true);
      dispatch(
        setEmailId({
          email_id: "",
        })
      );
    }
    if (registerUserResError || updateYtrUserResError || unsubscribeUserResError) {
      setIsRegError(true);
      setOpen(true);
    }
  }, [
    registerUserResponse,
    updateYtrUserResponse,
    unsubscribeUserResponse,
    registerUserResError,
    updateYtrUserResError,
    unsubscribeUserResError,
    dispatch,
    eventId,
  ]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const {
      firstName = "",
      lastName = "",
      mobileNo = "",
      describeYourRole = "",
      explainYourRole = "",
      companyName = "",
      designation = "",
      focusArea = "",
      email = "",
      experience = "",
      linkedInProfile = "",
      advertisementChannel = "",
      approved = false,
      registrationSuccess = false,
      roleSelection = "",
      id = "",
      isConsent = "",
    } = userData;

    if (validations()) {
      setUserData({
        ...userData,
        isError: true,
      });
    } else {
      setUserData({
        ...userData,
        isError: false,
      });
      const userRole = explainYourRole ? describeYourRole + "," + explainYourRole : describeYourRole;
      let myObj = {
        email: (email || "").toLowerCase().trim(),
        name: (firstName || "").trim() + " " + (lastName || "").trim(),
        mobile: (mobileNo.toString() || "").trim(),
        designation: (designation || "").trim(),
        focusArea: focusArea ? focusArea : "",
        company: (companyName || "").trim(),
        experience: experience,
        advertisement_channel: advertisementChannel,
        linkedin_profile_url: (linkedInProfile || "").trim(),
        approved: approved,
        registration_success: approved ? true : registrationSuccess,
        about_user: (userRole || roleSelection || "").trim(),
        social: localStorage.getItem("social_utm") ? localStorage.getItem("social_utm") : "",
        id: id,
        isconsent: isConsent === "agreed for consent" ? true : false,
        registered_in_venue: isAdmin,
      };
      try {
        if (isConsent === "agreed for consent") {
          setIsDisableRegBtn(true);
          if (existingUserData && existingUserData.member) {
            myObj["id"] = existingUserData["id"];
            updateYtrUser({
              eventId,
              userDataToUpdate: { ...myObj },
            });
          } else {
            registerUser({
              eventId,
              registerData: { ...myObj },
            });
          }
        } else {
          const userEmail = (userData.email || "").toLowerCase().trim();
          unsubscribeUser({
            eventId,
            userEmailId: userEmail,
          });
        }
      } catch (error) {
        //handle the error when the walk-ins closes or meets the threshold
        setOpen(false);
        // alert(error.message);
        setAlertMsg(error.message);
        handleOpenAlertBox();
        // setTimeout(() => {
        //   navigate('/spot');
        // }, 3000);
      }
    }
  };
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("utm_source")) {
      localStorage.setItem("social_utm", searchParams.get("utm_source"));
    }
    if (searchParams.get("isAdmin")) {
      setIsAdmin(true);
    }
    // if (registerUserData) {
    //   const { registration } = registerUserData;
    //   if (registration) {
    //     setOpen(true);
    //     storeRegisterSuccess(true);
    //   }
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const location = useLocation();
  const search = location.search;
  const isSpotReg = new URLSearchParams(search).get("spotreg");
  // const isTargetUser = userData.email.toString().toLowerCase().includes('target.com') || userData.companyName.toLowerCase().indexOf('target') >= 0;

  const onNavigate = () => {
    if (isAdmin) {
      navigate("/adminpanel");
      dispatch(
        setEmailId({
          email_id: "",
        })
      );
    } else {
      navigate("/");
    }
    // storeUserDetails({});
    // storeEmailId('');
  };

  const handleClose = () => {
    setOpen(false);
    onNavigate();
    dispatch(
      setEmailId({
        email_id: "",
      })
    );
  };

  const [open, setOpen] = useState(false);
  const [openSignUp, setopenSignUp] = useState(false);
  const containerStyles = !mobile ? { padding: "71px 15px 15px" } : {};

  // useEffect(() => {
  //   if (!openSignUp && emailId) {
  //     console.log('');
  //   }
  // }, [emailId]);

  function handleDismiss() {
    navigate("/");
  }

  return (
    <div id="register_page" style={{ minHeight: "100vh" }}>
      <SignUpPopup
        isDirectHit={!emailId ? true : false}
        openSignUp={openSignUp}
        setopenSignUp={setopenSignUp}
        handleDismiss={handleDismiss}
        existingUser={existingUserData}
      />
      <Modal
        open={openAlertBox}
        style={{ zIndex: 1500 }}
        onClose={handleCloseAlertBox}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{ textAlign: "right" }}>
            <button
              aria-label="close"
              onClick={() => handleCloseAlertBox()}
              className={classes.alertCloseButton}
            ></button>
          </div>

          <Typography
            id="modal-modal-description"
            sx={{
              fontSize: "25px",
              fontFamily: "HelveticaForTarget",
              textAlign: "center",
            }}
          >
            {alertMsg}
          </Typography>
        </Box>
      </Modal>

      <Dialog
        open={!openSignUp && emailId}
        // open={true}
        keepMounted
        onClose={() => handleClose()}
        aria-describedby="signup_successs"
        className={classes.dialog}
        style={{ width: !mobile ? "100% !important" : "80% !important" }}
        PaperProps={{
          style: {
            // borderRadius: 0,
            // width: !mobile ? '100%' : '80%',
            // backgroundColor: '#000000',
          },
        }}
      >
        <div style={{ textAlign: "right", padding: "5% 5% 0 0" }}>
          <button aria-label="close" onClick={() => handleClose()} className={classes.closeButton}></button>
        </div>
        <DialogContent className={classes.signupSuccessDialog}>
          <div style={{ backgroundImage: `url${r_form_bg}` }}>
            <Grid style={containerStyles}>
              <div className={classes.container}>
                <Grid container className={classes.header}>
                  <div style={{ width: "100%" }}>
                    {/* style={!mobile ? { margin: 0 } : { margin: '0px 0 12px 0' } } */}
                    <h2
                      className={classes.register}
                      style={{
                        margin: !mobile ? 0 : "0 0 12px 0",
                        color: "#FFFFFF",
                        fontFamily: "HelveticaForTarget",
                        textAlign: "left",
                      }}
                    >
                      Register to join
                    </h2>
                  </div>
                </Grid>
              </div>
              <Popup
                open={open}
                handleClose={handleClose}
                existingUser={existingUserData}
                updateYtrUserResponse={updateYtrUserResponse}
                registerUserResponse={registerUserResponse}
                unsubscribeUserResponse={unsubscribeUserResponse}
                isRegError={isRegError}
              />
              <form onSubmit={handleSubmit} id="registrationForm" aria-label="meetup registration form">
                <fieldset style={{ border: "none", padding: 0, margin: 0 }}>
                  <legend className={classes.modalBody} style={!mobile ? { padding: 0 } : { padding: "20px 0" }}>
                    Personal Details
                  </legend>
                  <Grid container>
                    <Grid item md={6} sm={12}>
                      <InputField
                        label="First Name"
                        type="text"
                        name="firstName"
                        classes={classes}
                        isError={errors.firstNameError}
                        value={userData.firstName}
                        handleChange={handleChange}
                        placeholder="Enter your first name"
                        errorLabel={"Please provide your valid first name"}
                        inputRef={firstNameRef}
                        required={true}
                      />
                    </Grid>
                    <Grid item md={6} sm={12}>
                      <InputField
                        label="Last Name"
                        type="text"
                        name="lastName"
                        value={userData.lastName}
                        classes={classes}
                        isError={errors.lastNameError}
                        handleChange={handleChange}
                        placeholder="Enter your last name"
                        errorLabel={"Please provide your valid last name"}
                        inputRef={lastNameRef}
                        required={true}
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <MobileNoInputField
                      label="Mobile"
                      type="text"
                      name="mobileNo"
                      value={userData.mobileNo}
                      classes={classes}
                      isError={errors.mobileNoError}
                      handleChange={handleChange}
                      placeholder="Enter your mobile number"
                      errorLabel={"Please provide your valid mobile number"}
                      inputRef={mobileNoRef}
                      required={true}
                    />
                    <InputField
                      label="Email Address"
                      type="text"
                      name="email"
                      value={userData.email}
                      classes={classes}
                      handleChange={handleChange}
                      disabled={true}
                    />
                  </Grid>
                </fieldset>
                <fieldset style={{ border: "none", padding: 0, margin: 0 }}>
                  <legend className={classes.modalBody} style={!mobile ? { padding: 0 } : { padding: "0  0 20px 0px" }}>
                    Professional Details
                  </legend>
                  <Grid container>
                    <InputField
                      label="Company"
                      type="text"
                      name="companyName"
                      value={userData.companyName}
                      classes={classes}
                      isError={errors.companyNameError}
                      handleChange={handleChange}
                      placeholder="Enter your company name"
                      errorLabel="Please provide your valid company name"
                      inputRef={companyNameRef}
                      required={true}
                    />
                    <InputField
                      label="Designation"
                      type="text"
                      name="designation"
                      value={userData.designation}
                      classes={classes}
                      isError={errors.designationError}
                      handleChange={handleChange}
                      placeholder="Enter your designation"
                      errorLabel="Please provide your valid designation"
                      required={true}
                      inputRef={designationRef}
                    />
                  </Grid>
                  <Grid container>
                    <Grid item md={6} sm={12}>
                      <InputField
                        label="LinkedIn Profile"
                        type="text"
                        name="linkedInProfile"
                        isSpotReg={isSpotReg}
                        required={isSpotReg ? false : true}
                        value={userData.linkedInProfile}
                        classes={classes}
                        isError={errors.linkedInProfileError}
                        handleChange={handleChange}
                        errorLabel={"Please provide your valid linkedIn profile"}
                        placeholder="Paste your profile url here"
                        inputRef={linkedInProfileRef}
                      />
                    </Grid>
                    <Grid item md={12} sm={12} sx={{ paddingTop: { sm: "20px", xs: "20px" } }}>
                      <div className={classes.labelStyles}>
                        {"Do you have experience in the following?"}
                        <span aria-hidden="true" style={{ color: "#FFF" }}>
                          *
                        </span>
                      </div>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="describeYourRole"
                        onChange={handleChange}
                      >
                        <Grid container>
                          <Grid item md={6} sm={12}>
                            <FormControlLabel
                              className={classes.labelStyles}
                              value="Supply chain"
                              control={
                                <Radio
                                  ref={describeYourRoleRef}
                                  onChange={handleRadioChange}
                                  className={classes.radioButton}
                                />
                              }
                              label="Supply chain"
                            />
                          </Grid>
                          <Grid item md={6} sm={12}>
                            <FormControlLabel
                              style={{ width: "100%" }}
                              className={classes.labelStyles}
                              value="Network optimization"
                              control={
                                <Radio
                                  ref={describeYourRoleRef}
                                  onChange={handleRadioChange}
                                  className={classes.radioButton}
                                />
                              }
                              label="Network optimization"
                            />
                          </Grid>
                          <Grid item md={6} sm={12}>
                            <FormControlLabel
                              style={{ width: "100%" }}
                              className={classes.labelStyles}
                              value="Inventory management"
                              control={
                                <Radio
                                  ref={describeYourRoleRef}
                                  onChange={handleRadioChange}
                                  className={classes.radioButton}
                                />
                              }
                              label="Inventory management"
                            />
                          </Grid>
                          <Grid item md={6} sm={12}>
                            <FormControlLabel
                              style={{ width: "100%" }}
                              className={classes.labelStyles}
                              value="Operations research"
                              control={
                                <Radio
                                  ref={describeYourRoleRef}
                                  onChange={handleRadioChange}
                                  className={classes.radioButton}
                                />
                              }
                              label="Operations research"
                            />
                          </Grid>
                          <Grid item md={6} sm={12}>
                            <FormControlLabel
                              style={{ width: "100%" }}
                              className={classes.labelStyles}
                              value="Transportation management"
                              control={
                                <Radio
                                  ref={describeYourRoleRef}
                                  onChange={handleRadioChange}
                                  className={classes.radioButton}
                                />
                              }
                              label="Transportation management"
                            />
                          </Grid>
                          <Grid item md={6} sm={12}>
                            <FormControlLabel
                              style={{ width: "100%" }}
                              className={classes.labelStyles}
                              value="Others"
                              control={
                                <Radio
                                  ref={describeYourRoleRef}
                                  onChange={handleRadioChange}
                                  className={classes.radioButton}
                                />
                              }
                              label="Others (please mention)"
                            />
                          </Grid>
                        </Grid>
                      </RadioGroup>
                      {showRoleTextBox ? (
                        <textarea
                          name="explainYourRole"
                          placeholder="Add your skills"
                          rows="4"
                          onChange={handleChange}
                          value={userData.explainYourRole}
                          // style={styles.inputStyles}
                          style={{ resize: "none", width: "50%" }}
                          className={classes.inputStyles}
                          ref={explainYourRole}
                          // required={true}
                        />
                      ) : null}
                      {!errors.describeYourRoleError ? null : (
                        <div className={classes.errorLabel}>{errors.describeYourRoleError}</div>
                      )}
                    </Grid>
                  </Grid>
                </fieldset>

                <Grid item md={12} sm={12} style={{ maxWidth: "100%", padding: "15px 0" }}>
                  <div className={classes.labelStyles}>
                    <p>
                      Please visit{" "}
                      <a
                        style={{ color: "#FFF" }}
                        href="https://gfc.target.com/stencil/stencil/1720674743497_Consent-Form-Designmeet.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        here
                      </a>{" "}
                      to read the consent form and then click on "I understand and give my consent" below.
                      <span aria-hidden="true" style={{ color: "#FFF" }}>
                        *
                      </span>
                    </p>
                    <p>
                      You do not need to do anything (sign, etc.) within the consent form document that opens in a
                      separate tab.
                    </p>
                    {/* {
                      'Please visit      <a style={{ color: '#FFF' }} href='https://multiplai.target.com/Consent_Form.pdf' target='_blank' rel='noreferrer'>
                        here
                      </a> to read the consent form and then click on "I understand and give my consent" below.'
                    } */}
                  </div>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="agreed"
                    name="isConsent"
                    onChange={handleChange}
                  >
                    <Grid container>
                      <Grid item md={12} sm={12}>
                        <FormControlLabel
                          id="Yes_consent_form_click"
                          style={{ width: "100%" }}
                          className={classes.labelStyles}
                          value="agreed for consent"
                          control={<Radio ref={consentOptionRef} className={classes.radioButton} />}
                          label="I understand the above and give my consent"
                        />
                      </Grid>
                      <Grid item md={12} sm={12}>
                        <FormControlLabel
                          id="No_consent_form_click"
                          className={classes.labelStyles}
                          value="not agreed for consent"
                          control={<Radio ref={consentOptionRef} className={classes.radioButton} />}
                          label="I do not consent (If you choose this option, we will not be able to take your registration forward.)"
                        />
                      </Grid>
                    </Grid>
                  </RadioGroup>
                  {!errors.consentError ? null : <div className={classes.errorLabel}>{errors.consentError}</div>}
                </Grid>

                {isSpotReg ? (
                  <RadioGroup
                    aria-labelledby="select the work shop"
                    style={{ width: "100%", height: mobile ? "auto" : "250px" }}
                    onChange={handleWorkshopRadioBtn}
                  ></RadioGroup>
                ) : null}
                <Grid item lg={12} md={12} sm={12} style={{}}>
                  <button
                    className={classes.customButton}
                    type="submit"
                    disabled={
                      (!existingUserData.member && userData.isConsent === "not agreed for consent") || isDisableRegBtn
                    }
                  >
                    {existingUserData.member && userData.isConsent === "not agreed for consent" ? "submit" : "register"}
                  </button>
                </Grid>
              </form>
            </Grid>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Registration;
