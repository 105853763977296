import { gql } from '@apollo/client';

export const LOGIN_USER = gql`
  mutation mobileLogin($email: String!, $password: String!) {
    mobileLogin(email: $email, password: $password) {
      accessToken
      refreshToken
      user {
        id
        first_name
        last_name
        contact_no
        email_id
        fullName
        organisation
        designation
        about_user
        linkedin_profile_url
        image_url
        areas_of_interest
        exp_years
        house_number
        landmark
        address_line1
        address_line2
        state
        city
        pincode
        created_at
        advertisement_channel
        source
        opted_for_kit
        has_unread_alerts
        survey_taken
      }
    }
  }
`;

export const CHECK_PIN = gql`
  mutation checkPin($email: String!, $authPIN: String!) {
    checkPin(email: $email, authPin: $authPIN)
  }
`;

export const SET_PASSWORD = gql`
  mutation setPassword($email: String!, $authPIN: String!, $password: String!) {
    setPassword(email: $email, authPin: $authPIN, password: $password) {
      accessToken
      refreshToken
      user {
        id
      }
    }
  }
`;

export const GET_ACCESS_TOKEN = gql`
  mutation getAccessToken($refreshToken: String!) {
    getAccessToken(refreshToken: $refreshToken) {
      accessToken
      user {
        id
        first_name
        last_name
        contact_no
        email_id
        fullName
        organisation
        designation
        about_user
        linkedin_profile_url
        image_url
        areas_of_interest
        exp_years
        house_number
        landmark
        address_line1
        address_line2
        state
        city
        pincode
        created_at
        advertisement_channel
        source
        opted_for_kit
        has_unread_alerts
        survey_taken
      }
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(emailId: $email)
  }
`;
export const ADD_FAVORITE_SESSION = gql`
  mutation FavoriteSession($id: String!) {
    favoriteSession(session_id: $id)
  }
`;

export const READ_ALERTS = gql`
  mutation readAlerts {
    readAlerts
  }
`;

export const CREATE_SURVEY = gql`
  mutation createOneSurvey($userId: String!, $surveyDetails: String!) {
    createOneSurvey(userId: $userId, surveyDetails: $surveyDetails)
  }
`;
export const CHECK_EXISTING_USER = gql`
  mutation checkExistingUser($emailId: String!) {
    checkExistingUser(emailId: $emailId)
  }
`;
export const REGISTER_USER = gql`
  mutation registration(
    $email_id: String!
    $contact_no: String!
    $designation: String!
    $first_name: String!
    $last_name: String!
    $organisation: String!
    $advertisement_channel: String!
    $exp_years: String!
    $linkedin_profile_url: String
    $about_user: String!
    $social: String!
    $id: String!
  ) {
    registration(
      email_id: $email_id
      contact_no: $contact_no
      designation: $designation
      first_name: $first_name
      last_name: $last_name
      organisation: $organisation
      advertisement_channel: $advertisement_channel
      exp_years: $exp_years
      linkedin_profile_url: $linkedin_profile_url
      about_user: $about_user
      social: $social
      id: $id
    )
  }
`;
export const SPOT_REGISTER_USER = gql`
  mutation onSpotRegistration(
    $email_id: String!
    $contact_no: String!
    $designation: String!
    $first_name: String!
    $last_name: String!
    $organisation: String!
    $advertisement_channel: String!
    $exp_years: String!
    $id: String!
    $linkedin_profile_url: String
    $about_user: String!
    $session_id: String!
    $social: String
  ) {
    onSpotRegistration(
      email_id: $email_id
      contact_no: $contact_no
      designation: $designation
      first_name: $first_name
      last_name: $last_name
      organisation: $organisation
      advertisement_channel: $advertisement_channel
      exp_years: $exp_years
      id: $id
      linkedin_profile_url: $linkedin_profile_url
      about_user: $about_user
      session_id: $session_id
      social: $social
    )
  }
`;

export const ADMIT_USER_TO_WORKSHOP = gql`
  mutation registerForWorkshops($sessionId: String!, $userId: String!) {
    registerForWorkshops(sessionId: $sessionId, userId: $userId)
  }
`;

export const ADMIT_PENDING_REJECTED_USER_TO_WORKSHOP = gql`
  mutation approveUserSession($sessionId: String, $userId: String) {
    approveUserSession(data: { user_id: $userId, session_id: $sessionId })
  }
`;

export const ADMIT_IBPS_USER = gql`
  mutation ibpsAdmittion($email: String!) {
    ibpsAdmittion(email: $email)
  }
`;
